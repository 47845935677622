import BillMgrApi from '@/services/api/BillMgrApi';
import { urlRusonyx, bmRusonyx, currentHost } from '@/layouts/LoginPage/mixins/currentProvider';

const state = () => ({
  theme: 'light',
  screen: {
    width: 0,
    height: 0,
  },
  isSidebarOpened: false,
  isModalOpened: false,
  isBannerOpened: true,
  headerHeight: 50,
  // host: 'https://my.rusonyx.ru/billmgr', // пробуем для кук
  host: bmRusonyx,
  url: urlRusonyx,
  mail: '',
});

const mutations = {
  SET_SCREEN(state, value) {
    state.screen = value;
  },
  SET_THEME(state, value) {
    state.theme = value;
  },
  SET_IS_MODAL_OPENED(state, value) {
    state.isModalOpened = value;
  },
  SET_IS_SIDEBAR_OPENED(state, value) {
    state.isSidebarOpened = value;
  },
  SET_IS_BANNER_OPENED(state, value) {
    state.isBannerOpened = value;
  },
  SET_HEADER_HEIGHT(state, value) {
    state.headerHeight = value;
  },
  SET_APP_HOST(state, value) {
    state.host = value;
  },
  SET_URL_HOST(state, value) {
    state.url = value;
  },
  SET_MAIL(state, value) {
    state.mail = value;
  },
};

const actions = {
  setHost({ commit }, host) {
    localStorage.setItem('host', host);
    BillMgrApi.defaults.baseURL = host;
    commit('SET_APP_HOST', host);
    commit('SET_URL_HOST', currentHost[host]);
  },
  // для вывода почты пользователя при нективированном аккаунте
  setMailUserLogins({ commit }, mail) {
    commit('SET_MAIL', mail);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
