import BillMgrApi from '@/services/api/BillMgrApi';
import { formDataParser } from '@/utils/parsers/formDataParser';

export default {
  fetchList({ commit }, payload = {}) {
    const silent = payload.silent || false;
    const mutation = payload.update ? 'UPDATE_LIST' : 'SET_LIST';
    if (payload.hasOwnProperty('silent')) delete payload.silent;
    if (payload.hasOwnProperty('update')) delete payload.update;
    if (!silent) commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const params = {
        show_metadata: 'on',
        newface: 'on',
        p_cnt: 1000,
        func: 'payment.recurring.stored_methods',
        ...payload,
      };
      BillMgrApi.get('billmgr', { params })
        .then(data => {
          if (data?.elem.length) commit(mutation, data.elem);
          if (data?.metadata?.toolbtndata) commit('SET_TOOLS', data.metadata.toolbtndata);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          if (!silent) commit('SET_IS_LOADING', false);
        });
    });
  },
  getViewCardList({ commit }, payload) {
    // console.log('nen', payload);
    return commit('setViewCardList', payload);
  },
  updateList({ dispatch }, payload = {}) {
    // console.log('updateList', 4444);
    const params = {
      update: true,
      ...payload,
    };
    return dispatch('fetchList', params);
  },
  fetchBiilingDocsList({ state, commit }, payload = {}) {
    const silent = payload.silent || false;
    if (payload.hasOwnProperty('silent')) delete payload.silent;
    if (!silent) commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const params = {
        // show_metadata: 'on',
        // newface: 'on',
        auth: localStorage.getItem('token'),
        sok: 'ok',
        out: 'bjson',
        ...payload,
      };
      if (!payload.func) params.func = state.func;
      const formData = formDataParser(params);
      BillMgrApi.post('billmgr', formData)
        .then(data => {
          if (data && data.ok === true) resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          if (!silent) commit('SET_IS_LOADING', false);
        });
    });
  },
  // reset store
  reset({ commit }, timeout = 0) {
    return new Promise(resolve => {
      setTimeout(() => {
        commit('SET_LIST', []);
        commit('SET_IS_LOADING', true);
        commit('SET_TOOLS', {});
        commit('setViewCardList', false);
        commit('SET_SORT', { prop: 'title', order: 'asc' });
        resolve();
      }, timeout);
    });
  },
};
