import { VpsTariff } from '@/models/BillMgr/VpsTariff';
import { CloudTariff } from '@/models/BillMgr/CloudTariff';
import { StackTariff } from '@/models/BillMgr/StackTariff';
import { VirtualTariff } from '@/models/BillMgr/VirtualTariff';
import { RentTariff } from '@/models/BillMgr/RentTariff';
import { PleskTariff } from '@/models/BillMgr/PleskTariff';
import { VirtuozzoTariff } from '@/models/BillMgr/VirtuozzoTariff';
import { ZmailTariff } from '@/models/BillMgr/ZmailTariff';
import { CDNTariff } from '@/models/BillMgr/CDNTariff';
import { VhostTariff } from '@/models/BillMgr/VhostTariff';
import { SoftTariff } from '@/models/BillMgr/SoftTariff';
import { BitrixTariff } from '@/models/BillMgr/BitrixTariff';
import { SSLTariff } from '@/models/BillMgr/SSLTariff';
import { SaasTariff } from '@/models/BillMgr/SaasTariff';
import { DedicTariff } from '@/models/BillMgr/DedicTariff';
import { ColoTariff } from '@/models/BillMgr/ColoTariff';
import { AntiDdosTariff } from '@/models/BillMgr/AntiDdosTariff';
import { DomainTariff } from '@/models/BillMgr/DomainTariff';
import { IdprotectTariff } from '@/models/BillMgr/IdprotectTariff';
import { Mo365Tariff } from '@/models/BillMgr/Mo365Tariff';
import { MicrosoftTariff } from '@/models/BillMgr/MicrosoftTariff';
import { PrivateTariff } from '@/models/BillMgr/PrivateTariff';
import { AcronisTariff } from '@/models/BillMgr/AcronisTariff';
import { DnsHostTariff } from '@/models/BillMgr/DnsHostTariff';

export const pathNameByService = {
  vds: 'vpsDetail',
  vdc2: 'tariffClouds',
  vdc3: 'stackMain',
  pleskshared: 'virtualDetail',
  rps: 'rentDetail',
  licplesk: 'pleskDetail',
  licvz: 'virtuozzoDetail',
  zmail: 'zmailDetail',
  cdnedge: 'cdnDetail',
  vhost: 'vhostDetail',
  soft: 'softDetail',
  bitrix_license: 'bitrixDetail',
  dummy_ssl_rsnx_oa_migrate: 'sslInfo',
  saas_services: 'saasDetail',
  dedic: 'dedicDetail',
  colocation: 'coloDetail',
  antiddosgcore: 'antiDdosDetail',
  domain: 'domainDetail',
  idprotect: 'idprotectDetail',
  microsoft_office: 'mo365Detail',
  microsoft: 'microsoftDetail',
  private_cloud: 'privateDetail',
  acronis: 'acronisDetail',
  dnshost: 'Domains',
};
export const moduleMain = {
  vds: 'moduleVPS',
  // vdc2: 'tariffClouds',
  // vdc3: 'stackMain',
  pleskshared: 'moduleVirtual',
  rps: 'moduleRent',
  licplesk: 'modulePlesk',
  licvz: 'moduleVirtuozzo',
  zmail: 'moduleZmail',
  cdn: 'moduleCDN',
  vhost: 'moduleVhost',
  soft: 'moduleSoft',
  bitrix_license: 'moduleBitrix',
  dummy_ssl_rsnx_oa_migrate: 'moduleSSL',
  // dummy_ssl_rsnx_oa_migrate: 'dummy_ssl_rsnx_oa_migrate.edit',
  dedic: 'moduleDedic',
  colocation: 'moduleColo',
  antiddosgcore: 'moduleAntiDdos',
  domain: 'moduleDomains.moduleDomainsDomains',
  idprotect: 'moduleIdprotect',
  microsoft_office: 'moduleMo365',
  microsoft: 'moduleMicrosoft',
  private_cloud: 'modulePrivate',
  acronis: 'moduleAcronis',
  saas_services: 'moduleSaas',
  dnshost: 'moduleDomains.moduleDomainsDnsHost',
};
export const tariffByService = {
  vds: VpsTariff,
  vdc2: CloudTariff,
  vdc3: StackTariff,
  pleskshared: VirtualTariff,
  rps: RentTariff,
  licplesk: PleskTariff,
  licvz: VirtuozzoTariff,
  zmail: ZmailTariff,
  cdnedge: CDNTariff,
  vhost: VhostTariff,
  soft: SoftTariff,
  bitrix_license: BitrixTariff,
  dummy_ssl_rsnx_oa_migrate: SSLTariff,
  saas_services: SaasTariff,
  dedic: DedicTariff,
  colocation: ColoTariff,
  antiddosgcore: AntiDdosTariff,
  domain: DomainTariff,
  idprotect: IdprotectTariff,
  microsoft_office: Mo365Tariff,
  microsoft: MicrosoftTariff,
  private_cloud: PrivateTariff,
  acronis: AcronisTariff,
  dnshost: DnsHostTariff,
};
