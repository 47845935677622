<template>
  <action-popup
    :active.sync="isActive"
    class="header-notification"
    popup-class="header-notification__popup"
    size="medium"
    ><template #default>
      <header-popup-activator
        :notify="list.length"
        icon="message"
        class="header-notification__activator"
      />
    </template>
    <div class="header-notification__content">
      <div class="header-notification__head">
        <div class="medium-title">
          {{ $t('title') }}
        </div>
        <plain-button
          icon="close"
          color="dim"
          size="small"
          class="header-notification__close"
          @click="isActive = false"
        />
      </div>
      <transition name="fade" mode="out-in">
        <base-loader v-if="isLoading && !list.length" />
        <div v-else-if="list.length" class="header-notification__list">
          <template v-for="(item, index) in list">
            <router-link
              :key="index"
              :to="{ name: 'CurrentTicket', params: { id: item.id } }"
              class="header-notification__item standart-text"
            >
              <div class="header-notification__item-title medium-title">
                {{ item.name }}
              </div>
              <div class="header-notification__item-meta small-text note-color">
                <span class="header-notification__item-date">
                  {{ item.last_message_l + ' ' + item.last_message_locale_time }}
                </span>
              </div>
            </router-link>
          </template>
        </div>
        <div v-else class="header-notification__empty standart-text">
          {{ $t('empty') }}
        </div>
      </transition>
      <div v-if="fullList.length" class="header-notification__more">
        <plain-button color="primary" @click="addNewTicket">
          {{ $t('new') }}
        </plain-button>
      </div>
      <div v-if="fullList.length" class="header-notification__more">
        <plain-button :to="{ name: 'OpenTicket' }" color="primary">
          {{ $t('all') }}
        </plain-button>
      </div>
    </div>
  </action-popup>
</template>

<script>
import ActionPopup from '@/components/ActionPopup/ActionPopup';
import HeaderPopupActivator from '../HeaderPopupActivator.vue';
import storeMixin from '@/layouts/Support/mixins/index';

export default {
  name: 'HeaderNotificationSupport',
  components: { ActionPopup, HeaderPopupActivator },
  mixins: [storeMixin],
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    list() {
      return this.$store.getters['moduleSupport/unreaded'];
    },
    fullList() {
      return this.$store.state.moduleSupport.allTickets;
    },
    isLoading() {
      return this.$store.state.moduleSupport.isLoading;
    },
  },
  mounted() {
    this.$store.dispatch('moduleSupport/fetchServiceList');
    this.$store.dispatch('moduleSupport/fetchAllTickets');
    this.$store.dispatch('moduleSupport/resetFiles');
  },
  methods: {
    routeTo(item) {
      this.$router.push({
        name: 'CurrentTicket',
        params: { id: item.id },
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "new_ticket": "Новый запрос",
    "title": "Новые сообщения поддержки",
    "empty": "Новых сообщений нет",
    "new": "Новый тикет",
    "all": "Смотреть все"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.header-notification {
  position: relative;

  &__activator {
    position: relative;
  }

  &__content {
    flexy(flex-start, stretch, nowrap, column);
    max-height: 'calc(100vh - %s)' % ($header-height + 10px);
  }

  &__head {
    flex: 0 0 auto;
    flexy(space-between, center);
    padding: 0.75rem 1.25rem 1.25rem;
    border-bottom: 1px solid var(--separator);
  }

  &__empty {
    padding: 1rem 1.25rem;
  }

  &__more {
    padding: 0 1.25rem 1rem;
  }

  &__list {
    padding: 1rem 0.5rem;
  }

  &__item {
    display: block;
    padding: 0.5rem;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      color: $primary-color;
    }

    & + & {
      margin-top: 0.25rem;
    }
  }
  & >>> &__popup {
    right: -50px;

    &::before {
      right: 60px;
    }
  }
}
</style>
