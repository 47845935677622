import axios from 'axios';
// import Vue from 'vue';

const defs = {
  // baseURL: 'https://objects.rusonyxcloud.ru/swift/v1/',
  baseURL: 'https://objects.rusonyxcloud.ru/swiftlk/v1',
};

const StorageApi = axios.create({
  baseURL: defs.baseURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: false,
});

StorageApi.interceptors.request.use(
  config => {
    const key = localStorage.getItem('stack');
    if (key) {
      // config.headers['x-auth-token'] = key;
      // config.headers['OpenStack-API-Version'] = 'volume 3.68, compute 2.90, octavia 2.5';
      config.baseURL = `${defs.baseURL}`;
    }

    // console.log(config);
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

StorageApi.interceptors.response.use(
  response => {
    // return response.data;
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

export default StorageApi;
