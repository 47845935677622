import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import actions from './actions';

import moduleApp from './modules/app';
import moduleRoutes from './modules/routes';
import moduleBasket from './modules/basket';
import moduleNotifications from './modules/notifications';
import moduleProviders from './modules/providers';
import { moduleReg } from '@/layouts/LoginPage/store';
import moduleProfile from '@/layouts/Profile/store';
import moduleStart from '@/layouts/StartLayout/store';
import moduleVPS from '@/layouts/VPS/store';
import moduleAll from '@/layouts/All/store';
import moduleSSL from '@/layouts/SSL/store';
import moduleZmail from '@/layouts/Zmail/store';
import moduleSaas from '@/layouts/Saas/store';
import moduleVhost from '@/layouts/Vhost/store';
import moduleLicenses from '@/layouts/Licenses/store';
// import moduleServices from '@/layouts/Services/store';
import moduleVirtual from '@/layouts/Virtual/store';
import moduleClouds from '@/layouts/Clouds/store';
import moduleDomains from '@/layouts/Domains/store';
import moduleSupport from '@/layouts/Support/store';
import moduleBilling from '@/layouts/Billing/store';
import moduleRent from '@/layouts/RentServer/store';
import moduleMo365 from '@/layouts/MOffice365/store';
import moduleAcronis from '@/layouts/CloudsBackup/store';
import moduleAntiDdos from '@/layouts/AntiDdosGcore/store';
import modulePrivate from '@/layouts/PrivateCloud/store';
import moduleDedic from '@/layouts/Dedic/store';
import moduleColo from '@/layouts/Colo/store';
import moduleIdprotect from '@/layouts/Idprotect/store';
import moduleBitrix from '@/layouts/Bitrix/store';
import modulePlesk from '@/layouts/Plesk/store';
import moduleCDN from '@/layouts/CDN/store';
import moduleSoft from '@/layouts/Soft/store';
import moduleVirtuozzo from '@/layouts/Virtuozzo/store';
import moduleMicrosoft from '@/layouts/Microsoft/store';
import moduleStack from '@/layouts/Stack/store';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions,
  modules: {
    moduleApp, // app states
    moduleRoutes, // permitted navigation
    moduleBasket, // basket, api
    moduleNotifications, // notifications
    moduleProviders, // providers
    moduleReg, //info about user (username, mail, token)
    moduleProfile, // info about balance, provider, and other
    moduleStart, //preview info about services
    moduleVPS, // info about vps and other with vps
    moduleSupport, // tickets
    moduleClouds,
    moduleSoft,
    moduleAll,
    moduleSaas,
    moduleZmail,
    moduleStack,
    moduleSSL, // info about ssl certificates in sales
    moduleLicenses, // info about licenses (plesk, virtuozzo)
    // moduleServices, // new VPS or Virtual (buy tariff and configure it)
    moduleVirtual, // info about virtual host
    moduleDomains,
    moduleBilling,
    moduleIdprotect,
    moduleRent,
    moduleCDN,
    moduleMicrosoft,
    moduleVhost,
    moduleVirtuozzo,
    modulePlesk,
    moduleBitrix,
    moduleMo365,
    moduleAcronis,
    moduleAntiDdos,
    modulePrivate,
    moduleDedic,
    moduleColo,
  },
  plugins: [
    createPersistedState({
      key: 'vuex',
      paths: [
        'moduleApp.theme',
        'moduleApp.isSidebarOpened',
        'moduleApp.isBannerOpened',
        'moduleVPS.key',
        'moduleVirtual.mode',
        'moduleVhost.mode',
        'moduleVPS.mode',
        'moduleCDN.mode',
        'moduleColo.mode',
        'moduleDedic.mode',
        'moduleZmail.mode',
        'moduleSaas.mode',
        'moduleClouds.key',
      ],
    }),
  ],
});
