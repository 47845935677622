<template>
  <base-table-row
    :columns="columns"
    v-bind="item"
    class="payers-table-row"
    col-class="payers-table-row__col"
  >
    <template #title>
      {{ item.title }}
    </template>
    <template #phone>
      {{ item.phone || '' }}
    </template>
    <template #type>
      {{ item.profiletype }}
    </template>
    <!--    <template #reconciliation>-->
    <!--      <plain-button color="primary">-->
    <!--        {{ $t('reconciliation') }}-->
    <!--      </plain-button>-->
    <!--    </template>-->
    <template v-if="contextMenu.length" #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="left"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="payers-table-row__popover"
      >
        <plain-button icon="more" class="payers-table-row__more" @click="togglePopoverState" />
        <template #popover>
          <base-context-menu
            :tariff="item"
            :menu="contextMenu"
            :module-main="moduleMain"
            :tools="tools"
            class="payers-table-row__context-menu"
          />
        </template>
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow.vue';
import popoverFix from '@/mixins/popoverFix';
import BaseContextMenu from '@/components/BaseContextMenu/BaseContextMenu.vue';
import payersContextMenu from '../mixins/payersContextMenu';
export default {
  name: 'PayersTableRow',
  components: { BaseTableRow, BaseContextMenu },
  mixins: [popoverFix, payersContextMenu],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      moduleMain: 'moduleBilling.modulePayers',
    };
  },
  computed: {
    provider() {
      return this.$store.state.moduleProviders.current;
    },
    tools() {
      return this.$store.state.moduleBilling.modulePayers.tools;
    },
    current() {
      return this.item;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "reconciliation": "Акт сверки"
  }
}
</i18n>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.payers-table-row {
  &__popover {
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      width: calc(100vw - 6rem);
    }
  }
}
</style>
