const modelOnOff = {
  trueValue: 'on',
  falseValue: 'off',
};
const modelYesNo = {
  trueValue: 'yes',
  falseValue: 'no',
};
const modelTrueFalse = {
  trueValue: 'true',
  falseValue: 'false',
};

export default class CheckboxComponent {
  constructor({ value, readonly, required, link_msg, link_url, desc }) {
    this.values = { on: true, off: false };
    this.value = value;
    this.desc = desc;
    this.disabled = readonly && readonly === 'yes';
    this.required = required && required === 'yes';
    const valueModel = this._getValueModel(this.value);
    this.trueValue = valueModel.trueValue;
    this.falseValue = valueModel.falseValue;
    if (link_msg && link_url) {
      this.link = {
        title: link_msg,
        href: link_url,
      };
    }
  }

  _getValueModel(value) {
    let model;
    switch (value) {
      case 'yes':
      case 'no':
        model = modelYesNo;
        break;
      case 'true':
      case 'false':
        model = modelTrueFalse;
        break;
      case 'on':
      case 'off':
      default:
        model = modelOnOff;
    }
    return model;
  }

  /**
   * DEPRECATED
   */
  get checked() {
    console.warn('DEPRECATED getter in CheckboxComponent');
    return this.values[this.value];
  }

  /**
   * DEPRECATED
   */
  set checked(value) {
    console.warn('DEPRECATED setter in CheckboxComponent');
    // not strict equal for supporting number and boolean value
    const index = Object.values(this.values).findIndex(i => i == value);
    // if found
    if (~index) this.value = Object.keys(this.values)[index];
    else throw new Error(`Unknown value: "${value}" in CheckboxComponent`);
  }
}
