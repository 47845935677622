import billMgrToolActions from '@/mixins/billmgr/billMgrToolActions';
import qs from 'qs';
import { IS_PROD } from '@/layouts/LoginPage/mixins/currentProvider';
export default {
  mixins: [billMgrToolActions],
  computed: {
    appHost() {
      return this.$store.state.moduleApp.host;
    },
  },
  methods: {
    handleRedirect(data) {
      // console.log(data);
      // let redirectLink = window.open();
      if (data.type && (data.type === 'blank' || data.type === 'url') && data.v) {
        const isInner = /^billmgr/.test(data.v);
        // console.log(isInner);
        const token = localStorage.getItem('token');
        if (!isInner) {
          // console.log('handleRedirect', token);
          const f = data.v[0];
          // console.log(f);
          const delim = f === '/' || f === '?' || f === '&' ? '' : '/';
          const url = this.appHost + delim + data.v + `&auth=${token}`;
          // if (data.v.startsWith('https://') && !IS_PROD)
          //   window.open('http://localhost:8080/#/' + data.v.split('/#/')[1], '_blanc');
          if (data.v.startsWith('https://')) window.open(data.v, '_self');
          let isOpen = window.open(url);
          // console.log(redirectLink);
          if (isOpen) {
            return Promise.resolve({ func: 'redirect', ok: true, url: url });
          } else {
            return Promise.resolve({ func: 'redirect', ok: false, url: url });
          }
        } else {
          window.open(
            this.appHost +
              '/' +
              data.v.replace('doc', 'pdf').replace('payment.print', 'payment.print.pdf') +
              `&auth=${token}`
          );
        }
        if (window.location.hash !== '#/billing/documents/payments')
          this.$router.push({ name: 'BillingPayments' });
      } else if (data.type && data.type === 'form') {
        const params = qs.parse(data.v);
        // console.log(params);
        return this.sendBillMgrAction(params);
      }
    },
  },
};
