export const STATE = {
  list: [],
  tariffList: [],
  items: [],
  services: [],
  current: '',
  paginList: [],
  state: {},
  paymethod: {},
  flag: {},
  reloadState: false,
  isLoading: true,
  sort: {
    prop: 'expiredate',
    order: 'asc',
  },
  mode: 'grid',
  tools: {},
};
