<template>
  <div class="main-sidebar" :class="classes">
    <div class="main-sidebar__header">
      <header-sidebar :open="open" @change="onChange" />
    </div>
    <div class="main-sidebar__body">
      <div class="main-sidebar__content scrollbar-dark">
        <div v-if="$slots.default" class="main-sidebar__top">
          <slot />
        </div>
        <div v-if="$slots.bottom" class="main-sidebar__bottom">
          <slot name="bottom" />
        </div>
        <div v-if="$slots.subbottom" class="main-sidebar__bottom">
          <slot name="subbottom" />
        </div>
      </div>
      <div
        v-if="$slots.footer"
        class="main-sidebar__footer"
        :class="{ hidden: !open && hideFooterOnClose }"
      >
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderSidebar from '@/components/Header/HeaderSidebar/HeaderSidebar';

export default {
  name: 'MainSidebar',
  components: { HeaderSidebar },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    hideFooterOnClose: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      const base = 'main-sidebar';
      return {
        [`${base}--open`]: this.open,
      };
    },
  },
  methods: {
    onChange(value) {
      this.$emit('change', value);
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.main-sidebar {
  position: fixed;
  transition: all 1s ease 0s;
  top: 0px;
  left: 0px;
  width: 1px;
  z-index: 999;

  &__header,
  &__body {
    background-color: var(--sidebar-theme);
    transition: background $anim-base-duration ease;
  }

  &__header {
    position: relative;
    z-index: 2;
    width: $sidebar-header-ms;
    height: $header-height-ms;

    +breakpoint(sm-and-up) {
      width: $sidebar-header-sm;
      height: $header-height;
    }
    +breakpoint(md-and-up) {
      width: $sidebar-header-md;
      height: $header-height;
    }
    +breakpoint(lg-and-up) {
      width: $sidebar-width;
    }
  }

  &__body {
    position absolute;
    top: 0;
    bottom: 0;
    flexy(space-between, stretch, nowrap, column);
    z-index: 1;
    padding-top: $header-height-ms;
    size(100vw, 100vh);
    transition: transform 0.8s ease-out, background-color $anim-base-duration ease;
    transform: translateX(-100%);
    transform-origin: top left;
    overflow: hidden;

    ^[0]--open & {
      transform: translateX(0);
    }

    +breakpoint(sm-and-up) {
      padding-top: $header-height;
      width: $sidebar-header-sm;
    }
    +breakpoint(md-and-up) {
      width: $sidebar-header-md;
    }
    +breakpoint(lg-and-up) {
      transform: translateX(-1 * ($sidebar-width - $sidebar-width-min));
      width: $sidebar-width;
    }
  }

  &__content {
    flex: 1 1 100%;
    margin: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    //min-height: 100%;
    max-height: 100%;
    overflow-y: auto;
    padding: 2rem 1.5rem 1.5rem;
    opacity: 0;
    transition: opacity 0.5s ease-out;

    ^[0]--open & {
      opacity: 1;
      transition: opacity 0.5s 0.3s ease-out;
    }
    +breakpoint(lg-and-up) {
      opacity: 1;
    }
  }

  &__footer {
    flex: 0 0 auto;
    margin: auto 0 0;
    padding: 1.25rem 1.5rem;
    border-top: 1px solid var(--sidebar-separator);
    color: var(--sidebar-text);
    transition: opacity $anim-base-duration ease;

    &.hidden {
      opacity: 0;
    }
  }
}
</style>
