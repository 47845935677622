import store from '@/store';

// console.log('redirect', redirect);

// console.log(store.state.moduleStart);

export const RouteBilling = {
  name: 'Billing',
  path: '/billing',
  component: () => import(/* webpackChunkName: "billing-main" */ './container.vue'),
  meta: {
    isShow: true,
    isLink: false,
    name: 'Финансы',
    navigation: {
      account: {
        key: 'billing',
        // icon: 'el-icon-coin',
        icon: 'el-icon-bank-card',
        order: 1,
      },
    },
    access: ['customer', 'finance'],
  },
  redirect: {
    name: 'BillingDeposit',
  },
  children: [
    {
      path: '',
      name: 'BillingMain',
      component: () => import(/* webpackChunkName: "billing-main" */ './pages/Main/Main.vue'),
      redirect: to => {
        let redirect = store.state.moduleStart.userWithoutPayment
          ? 'BillingPayments'
          : 'BillingDeposit';
        return {
          name: redirect,
        };
      },
      // redirect: {
      //   name: redirect,
      // },
      children: [
        {
          path: 'deposit',
          name: 'BillingDeposit',
          access: ['finance.payment'],
          meta: {
            isShow: true,
            isLink: false,
            name: 'Пополнение счета',
            navigation: {
              miniProfile: {
                key: 'payments',
                order: 2,
              },
            },
          },
          component: () =>
            import(/* webpackChunkName: "billing-deposite" */ './pages/Main/views/MakeDeposit.vue'),
        },
        {
          path: 'history',
          name: 'BillingHistory',
          meta: {
            isShow: true,
            isLink: false,
            name: 'История платежей',
          },
          access: ['finance.expense'],
          redirect: { name: 'BillingHistorySummary' },
          component: () =>
            import(
              /* webpackChunkName: "billing-history" */ './pages/Main/views/History/BillingHistory.vue'
            ),
          children: [
            {
              path: 'summary',
              name: 'BillingHistorySummary',
              meta: {
                isShow: true,
                isLink: false,
                name: 'Сводка',
              },
              component: () =>
                import(
                  /* webpackChunkName: "billing-history-summary" */ './pages/Main/views/History/BillingHistorySummary.vue'
                ),
            },
            {
              path: 'details',
              name: 'BillingHistoryDetails',
              meta: {
                isShow: true,
                isLink: false,
                name: 'Подробно',
              },
              component: () =>
                import(
                  /* webpackChunkName: "billing-history-details" */ './pages/Main/views/History/BillingHistoryDetails.vue'
                ),
            },
          ],
        },
        {
          path: 'documents',
          name: 'BillingDocs',
          meta: {
            isShow: true,
            isLink: false,
            name: 'Документы',
          },
          redirect: { name: 'BillingPayments' },
          component: () =>
            import(
              /* webpackChunkName: "billing-docs" */ './pages/Main/views/BillingDocs/BillingDocs.vue'
            ),
          children: [
            {
              path: 'payments',
              name: 'BillingPayments',

              component: () =>
                import(
                  /* webpackChunkName: "billing-docs" */ './pages/Main/views/BillingDocs/BillingDocsTable.vue'
                ),
              props: true,
              meta: {
                itemtype: 'payment',
                isShow: true,
                isLink: false,
                name: 'Счета',
              },
            },
            {
              path: 'invoices',
              name: 'BillingInvoices',
              component: () =>
                import(
                  /* webpackChunkName: "billing-docs" */ './pages/Main/views/BillingDocs/BillingDocsTable.vue'
                ),
              props: true,
              meta: {
                itemtype: 'invoice',
              },
            },
            {
              path: 'contracts',
              name: 'BillingContracts',
              access: ['customer.contract'],

              component: () =>
                import(
                  /* webpackChunkName: "billing-docs" */ './pages/Main/views/BillingDocs/BillingDocsTable.vue'
                ),
              props: true,
              meta: {
                itemtype: 'contract',
                isShow: true,
                isLink: false,
                name: 'Договоры',
              },
            },
            {
              path: 'customdocs',
              name: 'BillingCustomdocs',
              access: ['finance.customdocs'],
              component: () =>
                import(
                  /* webpackChunkName: "billing-docs" */ './pages/Main/views/BillingDocs/BillingDocsTable.vue'
                ),
              props: true,
              meta: {
                itemtype: 'customdocs',
                isShow: true,
                isLink: false,
                name: 'Закрывающие документы',
              },
            },
          ],
        },
        {
          path: 'orders',
          name: 'BillingOrders',
          access: ['customer.order'],
          component: () =>
            import(/* webpackChunkName: "billing-orders" */ './pages/Main/views/MyOrders.vue'),
        },
        {
          path: 'promisepayment',
          name: 'BillingPromisePayment',
          meta: {
            isShow: true,
            isLink: false,
            name: 'Обещанный платеж',
          },
          access: ['finance.promisepayment.add'],
          component: () =>
            import(
              /* webpackChunkName: "billing-promised" */ './pages/Main/views/PromisePayment/PromisePayment.vue'
            ),
        },
        {
          path: 'autopayment',
          name: 'BillingAutoPayment',
          meta: {
            isShow: true,
            isLink: false,
            name: 'Автоплатеж',
          },
          access: ['finance.payment.recurring.settings'],
          component: () =>
            import(
              /* webpackChunkName: "billing-promised" */ './pages/Main/views/AutoPayment/AutoPayment.vue'
            ),
        },
        {
          path: 'payers',
          name: 'BillingPayers',
          meta: {
            isShow: true,
            isLink: false,
            name: 'Плательщики',
          },
          access: ['customer.profile'],
          component: () =>
            import(/* webpackChunkName: "billing-payers" */ './pages/Main/views/Payers.vue'),
        },
      ],
    },
  ],
};
