<template>
  <div class="main-label" :class="classes">
    <slot>{{ text }} </slot>
  </div>
</template>

<script>
import { baseColors } from '@/models/base/baseColors';
export default {
  name: 'MainLabel',

  props: {
    color: {
      type: String,
      default: 'success',
      validator: value => !!baseColors[value],
    },
    theme: {
      type: String,
      default: 'filled',
      validator: value => ['filled', 'plain', 'tags', 'other'].includes(value),
    },
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    classes() {
      const base = 'main-label';
      return {
        [`${base}--${baseColors[this.color]}`]: !!this.color,
        [`${base}--${this.theme}`]: this.theme,
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
$ml-colors = {
  success: {
    base: $success-color,
    alt: $color-light.white,
    black: #dcdcdc,
    //black: $color-dark.dark,
    note: $color-dark.dark,
    pay: $color-green.lighter, //#4cbf86
  },
  error: {
    base: $error-color,
    alt: $color-light.white,
  },
  primary: {
    base: $primary-color,
    alt: $color-light.white,
  },
  warning: {
    base: $warning-color,
    alt: $color-light.white,
  }
  note: {
    //base: $note-color,
    base: $color-light.light-blue,
    alt: $color-light.white,
  }
};
$ml-themes = {
  filled: {
    bg: 'base',
    color: 'alt',
    border: false,
    radius: $border-radius-base,
    size: 12px,
    padding: 1px 1rem 2px,
  },
   tags: {
    bg: 'base',
    color: 'alt',
    border: false,
    hover: $color-blue,
    radius: $border-radius-base,
    size: 12px,
    padding: 2px 0.5rem 2px,
  },
  other: {
    bg: 'black',
    color: 'note',
    border: false,
    opacity: 0.6,
    //height: 14px,
    cursor: pointer,
    radius: 0px 6px,
    size: 10px,
    padding: 1px 0.75rem 1px,
  },
  plain: {
    bg: false,
    color: 'base',
    border: false,
    radius: false,
    size: 14px,
    padding: 0,
  },
}
.main-label {
  line-height: 14px;
  display: inline-block;

  for $key, $theme in $ml-themes {
    for $color, $value in $ml-colors {
      &--{$key}&--{$color} {
        font-size: $theme.size;
        padding: $theme.padding;
        color: $value[$theme.color];
        if $theme.bg {
          background: $value[$theme.bg];
        }
        if $theme.border {
          border: 1px solid $value[$theme.border];
        }
        if ($theme.radius) {
          border-radius: $theme.radius;
        }
        if ($theme.height ) {
          height: $theme.height;
        }
        if ($theme.opacity  ) {
          opacity: $theme.opacity;
        } if ($theme.cursor  ) {
        cursor: $theme.cursor;
        }
      }
    }
  }
}
</style>
