import container from './container.vue';

export const RouteAll = {
  path: '/all',
  name: 'all',
  meta: {
    isShow: true,

    isLink: false,
    name: 'Все услуги',
  },
  component: container,
};
