let configUrl;
switch (true) {
  case window.location.origin.includes('localhost'):
    configUrl = 'my';
    break;
  case window.location.origin.includes('mypreprod'):
    configUrl = 'mypreprod';
    break;
  case window.location.origin.includes('mystage'):
    configUrl = 'mystage';
    break;
  case window.location.origin.includes('myupdate'):
    configUrl = 'myupdate';
    break;
  case window.location.origin.includes('my'):
    configUrl = 'my';
    break;
  default:
    configUrl = 'my';
}
export const PROD = configUrl;
export const NOTWORKDAY = [
  '30-12-2023',
  '31-12-2023',
  '01-01-2024',
  '02-01-2024',
  '03-01-2024',
  '04-01-2024',
  '05-01-2024',
  '06-01-2024',
  '07-01-2024',
  '08-01-2024',
  '23-02-2024',
  '24-02-2024',
  '25-02-2024',
  '08-03-2024',
  '09-03-2024',
  '10-03-2024',
  '28-04-2024',
  '29-04-2024',
  '30-04-2024',
  '01-05-2024',
  '09-05-2024',
  '10-05-2024',
  '11-05-2024',
  '12-05-2024',
  '12-06-2024',
  '03-11-2024',
  '04-11-2024',
];
export const FAQLINK = {
  acronis: 'https://support.rusonyx.ru/index.php?/Knowledgebase/List/Index/109',
};
export const WORKDAY = [''];
