<template>
  <div class="signup">
    <h1 class="page-title">
      {{ $t('title') }}
    </h1>
    <transition name="fade" mode="out-in" @after-enter="setFocus()">
      <div>
        <page-block size="bigger">
          <div class="clouds-stats__grid l-flex-1_md-2">
            <main-card class="reregistration__card reregistration__new">
              <div
                v-if="!['4', '5'].includes(provider)"
                class="signup__form"
                @keyup.enter="sendRequestTo"
              >
                <base-input
                  v-model="sendingText"
                  :hint="$t('fields.id.hint')"
                  type="text"
                  :mask="'9{1,999}'"
                  :required="true"
                  :label="$t('fields.id.label')"
                  class="signup__field"
                />
              </div>
              <div class="send-message__send">
                <div class="send-message__container">
                  <vue-dropzone
                    id="dropzone"
                    ref="Dropzone"
                    class="dropzone"
                    :duplicate-check="true"
                    :include-styling="false"
                    :use-custom-slot="true"
                    :options="dropzoneOptions"
                    @vdropzone-file-added="fileAdded"
                    @vdropzone-success-multiple="success"
                  >
                    <div class="dropzone-container">
                      <plain-button
                        v-if="this.$store.getters['moduleSupport/GET_FILES'].length < 5"
                        color="primary"
                        class="dropzone-container__file-link"
                      >
                        <div class="send-message__text">
                          <svg-icon name="clip" class="send-message__icon" />{{
                            typeOrg ? $t('add_file_ur') : $t('add_file')
                          }}
                        </div>
                      </plain-button>
                    </div>
                  </vue-dropzone>
                  <AttachmentList
                    ref="attach"
                    class="send-message__container-dropzone__list"
                    :temp-attachments="getTempAttachments"
                    :attachments="getAttachments"
                  />
                </div>
                <div v-if="!isAttachment" class="signup__error standart-text error-color">
                  {{ typeOrg ? $t('isAttachment.ur') : $t('isAttachment.fiz') }}
                </div>
                <div class="send-message__container-btn">
                  <base-button
                    class="send-message__send-btn"
                    :disabled="isDisabled"
                    :loading="sendingRequest"
                    :tools="tools.edit"
                    @click="sendRequestTo()"
                    >{{ $t('btn.send') }}
                  </base-button>
                </div>
              </div>
            </main-card>
            <main-card class="reregistration__new-info">
              <div class="partners__desc standart-text">
                <h2 class="sH2">{{ $t('doc.text_1') }}</h2>
                <br />
                {{ $t('doc.text_2') }}
                <br />
                <br />
                {{ typeOrg ? $t('doc.text_3_ur') : $t('doc.text_3_fiz') }}
                <br />
                <br />
                <div class="reregistration__item-dell">
                  {{ $t('doc.text_4') }}
                </div>
              </div>
              <br />
              <br />
              <div v-if="typeOrg">
                <plain-button
                  v-tooltip="{
                    content: 'скачать',
                    placement: 'top-start',
                    container: 'body',
                  }"
                  color="primary"
                  class="security-password-left"
                  @click="getDocument()"
                >
                  {{ $t('downloadDocument') }}
                </plain-button>
                <br />
                <span class="note-text">{{ $t('downloadDocument_info') }}</span>
              </div>
            </main-card>
          </div>
        </page-block>
      </div>
    </transition>
  </div>
</template>

<script>
//TODO: Заложил на будущее фундамент для регистрации аккаунтов из под клиента, если потребуется для переоформлений

import BaseInput from '@/components/BaseInput/BaseInput';
import setFocus from '@/mixins/setFocus';
import generatePassword from '@/mixins/generatePassword';
import { IS_PROD } from '@/layouts/LoginPage/mixins/currentProvider';
import reRegistrationSuccess from '@/layouts/Profile/mixins/reRegistrationSuccess';
import vue2Dropzone from 'vue2-dropzone';
import AttachmentList from '@/layouts/Support/component/AttachmentList.vue';
import Vue from 'vue';
import getPayerInfo from '@/layouts/Profile/mixins/getPayerInfo';
import { saveAs } from 'file-saver';

export default {
  name: 'ReRegistration',
  components: {
    BaseInput,
    vueDropzone: vue2Dropzone,
    AttachmentList,
  },
  // BaseButtonsGroup, BaseSelect, Policy
  mixins: [setFocus, reRegistrationSuccess, generatePassword, getPayerInfo],
  data() {
    return {
      moduleMain: 'moduleBilling.modulePayers',
      typeValue: 'new',
      statusValue: 'physical',
      partner: '',
      currentId: '',
      payersData: '',
      sendingRequest: false,
      error: '',
      isAttachment: true,
      internalValue: '1093256',
      service: 'null',
      sendingText: '',
      tempAttachments: [],
      attachments: [],
      dropzoneOptions: {
        // url: 'https://my.rusonyx.ru/billmgr',
        url: `${this.$store.state.moduleApp.host}/billmgr`,
        method: 'post',
        maxFilesize: 10,
        maxFiles: 5,
        autoProcessQueue: false,
        clickable: true,
        includeStyling: false,
        previewsContainer: false,
        uploadMultiple: true,
        headers: {
          'Content-Type': 'application/json',
        },
        parallelUploads: 5,
        // acceptedFiles: '.jpeg,.jpg,.png,.gif,.JPEG,.JPG,.PNG,.GIF,.pdf,.doc,.docx',
      },
      fields: [
        {
          name: 'id',
          type: 'text',
          invalid: false,
          value: '',
        },
      ],
    };
  },
  computed: {
    host() {
      return window.location.href.split('#').slice(0, 1);
    },
    isDisabled() {
      return !this.sendingText || this.tempAttachments.length === 0;
    },
    profileAccount() {
      return this.$store.state.moduleProfile.profile.account;
    },
    department() {
      return this.$store.getters['moduleSupport/GET_DEPARTMENT'];
      // .filter(x =>
      // this.isWorkingDay ? x : x.k !== '2'
      // );
    },
    current() {
      return this.$store.state.moduleBilling.modulePayers.list[0];
    },
    tools() {
      return this.$store.state.moduleBilling.modulePayers.tools;
    },
    isSubject() {
      return `Переоформление ${this.sendingText}`;
    },
    getParams() {
      if (!this.current) return [];
      if (!this.tools.edit) return [];
      const arr = [];
      if (this.tools && this.tools.edit) {
        arr.push({
          key: 'edit',
          tool: this.tools.edit,
        });
      }
      return arr;
    },
    getTempAttachments() {
      return this.tempAttachments;
    },
    getAttachments() {
      return this.attachments;
    },
    IS_PROD() {
      return IS_PROD;
    },
    // currentField() {
    //   return findAllByKey(currentProvider, this.currentHost).toString();
    // },
    currentHost() {
      return IS_PROD ? localStorage.getItem('host') : this.$store.state.moduleApp.host;
    },
    typeOrg() {
      if (this.$store.state.moduleBilling.modulePayers.list.length) {
        return this.$store.state.moduleBilling.modulePayers.list[0].profiletype_orig === '2';
      } else return false;
    },

    provider() {
      if (IS_PROD) {
        if (this.currentHost.includes('infobox')) {
          return '5';
        } else if (this.currentHost.includes('zenon')) {
          return '4';
        } else if (this.currentHost.includes('astracloud')) {
          return '6';
        } else if (this.currentHost.includes('rusonyx')) {
          return '3';
        } else if (this.currentHost.includes('caravan')) {
          return '2';
        } else return '3';
      } else {
        return this.$store.state.moduleProviders.current
          ? this.$store.state.moduleProviders.current
          : null;
      }
    },
    sendingTextRequest() {
      if (this.typeOrg) {
        return `Организация ${this.current.name}, ИНН ${this.payersData.vatnum.value} просит перенести все услуги на аккаунт ID ${this.sendingText}`;
      } else
        return `Я, ${this.payersData.person.value},

паспортные данные:
------------------

 - Серия и номер паспорта: ${this.payersData.passport.value}
 - Кем выдан паспорт: ${this.payersData.passport_org.value}
 - Дата выдачи паспорта: ${this.payersData.passport_date.value}

 прошу перенести все услуги на аккаунт ID ${this.sendingText}`;
    },
    template() {
      return `                   *** На бланке организации***




  исходящий номер, дата                                  ${this.templateHead}




Организация ${this.current.name},  ID ${this.profileAccount}  в связи с (___указать причину___) просит передать все услуги Организации (___название организации___),  номер учетной записи ID ${this.sendingText}.


Должность
Фамилия, имя, отчество
Подпись, дата
Печать организации`;
    },

    templateHead() {
      if (this.provider === '3') {
        return `  Директору предприятия
                                                           ООО «Астра Облако»
                                                           К.В. Анисимову`;
      } else {
        return ` Генеральному директору
                                                          ООО «ВДЦ Караван»
                                                          Анисимову К. В.`;
      }
    },
    doneMessage() {
      return `Заявка ${this.currentId} на переоформление принята. <br /><br /> Обработка заявок осуществляется по рабочим дням в период с 9:00 до 17:00. Ожидайте, пожалуйста.`;
    },
  },
  watch: {
    sendingText() {
      if (this.sendingText && !this.tempAttachments.length) {
        this.isAttachment = false;
      }
    },
    tempAttachments(newFile) {
      if (newFile) {
        this.isAttachment = true;
      }
    },
  },
  mounted() {
    // this.fetch();
    this.partner =
      this.$route.query && this.$route.query.partner ? this.$route.query.partner : null;
    // console.log(this.$route.query.partner);
  },
  methods: {
    getDocument() {
      const blob = new Blob([this.template], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, 'rsnz_ur_ticket.odt');
    },
    sendRequestTo() {
      this.getPayersData(this.getParams[0]);
      setTimeout(() => {
        if (this.payersData && (this.payersData.passport || this.payersData.vatnum)) {
          this.sendRequest();
        }
      }, 500);
    },
    sendRequest() {
      this.getPayersData(this.getParams[0]);
      this.sendingRequest = true;
      const files = this.$refs.Dropzone.getQueuedFiles();
      let formData = new FormData();
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          let file = files[i];
          formData.append(`file_${i + 1}`, file);
          formData.delete(`file[${i}]`);
        }
      }
      formData.append('auth', localStorage.getItem('token'));
      formData.append('sok', 'ok');
      formData.append('message', this.sendingTextRequest);
      formData.append('ticket_item', this.service);
      formData.append('subject', this.isSubject);
      formData.append('client_department', this.internalValue);
      formData.append('out', 'bjson');
      formData.append('func', 'clientticket.edit');

      this.$store
        .dispatch('moduleSupport/sendMessage', formData)
        .then(data => {
          if (data.ok) {
            // return Promise.all([this.$store.dispatch('moduleSupport/fetchAllTickets')])
            return Promise.all([this.$store.dispatch('moduleSupport/fetchServiceList')])
              .then(() => setTimeout(() => (this.sendingRequest = false), 500))
              .then(() => (this.sendingText = ''))
              .then(() => (this.currentId = data.id))
              .then(() => this.$refs.attach.removeFile())
              .then(() => this.$nextTick());
          } else {
            // console.log(data);
          }
        })
        .catch(e => {
          // this.$modals.close();
          this.showAlert(e.msg);
          this.sendingRequest = false;
          this.showError(e);
        })
        .finally(() => {
          // this.$modals.close();
          // this.$store.dispatch('moduleSupport/fetchServiceList');
          this.sendMessageToDone();
        });
    },
    sendMessageToDone() {
      if (!this.modal) this.makeModal('sendMessageDone');
      Vue.set(this.modal, 'text', this.doneMessage);
      Vue.set(this.modal, 'html', true);
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'closeOnBackdrop', false);
      Vue.set(this.modal, 'footer', {
        centered: true,
        confirm: {
          props: { title: this.$t('btn.url') },
          on: {
            click: () => {
              window.location.href = `${this.host}#/support/tickets/${this.currentId}`;
              this.$modals.close();
            },
          },
        },
        cancel: {
          props: { title: this.$t('btn.ok') },
          on: { click: () => this.$modals.close() },
        },
      });
      this.sendingMessage = false;
    },
    makeModal(name) {
      this.$modals.open({
        name: name,
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
      });
    },
    fileAdded(file) {
      let attachment = {};
      attachment.file = file;
      attachment._id = file.upload.uuid;
      attachment.title = file.name;
      attachment.type = 'file';
      attachment.extension = '.' + file.type.split('/')[1];
      attachment.isLoading = true;
      attachment.progress = null;
      attachment.size = file.size;
      if (
        attachment.size < 10 * 1024 * 1024 &&
        this.tempAttachments.length < 5 &&
        !this.tempAttachments.map(t => t.title).includes(attachment.title)
      ) {
        if (this.tempAttachments.length !== this.$store.state.moduleSupport.files.length) {
          this.$store.dispatch('moduleSupport/resetFiles');
        }
        this.tempAttachments = [...this.tempAttachments, attachment];
        this.$store.dispatch('moduleSupport/addFiles', file);
      } else {
        this.showError();
      }
    },
    success() {
      this.tempAttachments = [];
      this.attachments = [];
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "done": "Запрос успешно отправлен",
    "title": "Переоформление",
    "add_file": "Загрузить скан паспорта",
    "add_file_ur": "Загрузить скан заявления",
    "downloadDocument": "Шаблон заявления",
    "downloadDocument_info": "*Формат файла .odt (LibreOffice)",
    "isAttachment": {
      "fiz": "Пожалуйста, загрузите скан копию паспорта владельца аккаунта (основной разворот и страницу 19, о ранее выданных паспортах) для подтверждения личности.",
      "ur": "Пожалуйста, загрузите скан копию заявления на бланке организации с печатью и подписью генерального директора."
    },
    "btn": {
      "send": "Подтвердить",
      "url": "К тикету",
      "ok": "Закрыть"
    },
    "tab": {
      "registration": "Регистрация нового аккаунта",
      "account": "Существующий аккаунт"
    },
    "doc": {
      "text_1": "Переоформление договора на другое лицо (Смена плательщика)",
      "text_2": "Процедуру переоформления договора следует выполнять в ситуациях, когда требуется изменить плательщика за услуги хостинга/домена. В целях сохранения истории платежей это потребует регистрации нового аккаунта и переноса всех услуг на него.",
      "text_3_fiz": "Переоформление происходит путем передачи услуг с одного аккаунта на другой. Для старта процедуры, пожалуйста, введите код клиента нового аккаунта, его можно посмотреть на главной странице в сводке нового аккаунта, а так же загрузите скан копию паспорта владельца отдающего аккаунта. Основной разворот и страницу 19, о ранее выданных паспортах. Если у нового плательщика еще нет аккаунта, выйдите из системы и зарегистрируйте новый аккаунт. После регистрации добавьте плательщика, сделать это можно в меню Финансы - Плательщики.",
      "text_3_ur": "Переоформление происходит путем передачи услуг с одного аккаунта на другой. Для старта процедуры, пожалуйста, скачайте заявление о передаче услуг, заполните, подпишите и поставьте печать.  После чего приложите его к форме, а так же введите код клиента принимающего аккаунта. Если у нового плательщика еще нет аккаунта, выйдите из системы и зарегистрируйте новый аккаунт. После регистрации добавьте плательщика, сделать это можно в меню Финансы - Плательщики.",
      "text_4": "Обратите, пожалуйста, внимание, что речь в данной статье идёт только о переоформлении договора, заключенного с ООО «Астра Облако». Если Вы хотите передать права на домен у регистратора доменного имени, то Вам необходимо выполнить процедуру передачи прав на домен. Для этого, после переоформления, напишите новый запрос в службу поддержки из личного кабинета."
    },
    "fields": {
      "id": {
        "hint": "Код клиента можно найти на главной странице аккаунта, на который будут передаваться услуги.",
        "label": "Код клиента нового аккаунта"
      }
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.base-input {
  &__label{
&-asterisk{
  color: $color-red.base;
  font-size: large;
}
  }
}
.send-message__send {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  flex-direction: column;
  align-items: center;
}
.send-message__send-btn {
  margin-top: 20px
  margin-right: 0;
  +breakpoint(ms-and-up) {
    margin-top: 60px;
  }
}
.send-message__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-self: flex-start;
  width: 100%;
}
.send-message__container-dropzone__list{
  display: flex;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  position: relative;
  z-index: 1;

}
.send-message__container-btn {
  display: flex;
  align-items: flex-end;
}
.send-message__text {
  font-size: 0.8rem;
}
.send-message__title {
  display: flex;
  font-size: $font-size-base;
  padding-bottom: 10px;
  gap: 0.125rem;
}
.dropzone__list {
  display: flex;
}

.reregistration{
  &__card{
    width: 30%;
    max-width: 545px;
    height: max-content;

    +breakpoint(ms-and-down) {
      width: 100%;
      max-width: 100%;
    }
  }
  &__new{
    margin-top: 1.25rem;
  }
  &__new-info{
    margin-top: 1.25rem;
    margin-left: 1.5rem;
    max-width: 65%;

    +breakpoint(ms-and-down) {
      max-width: 100%;
      margin-left: 0;
    }
  }
  &__item{
    &-dell{
    color: $color-red.medium;
  }
  }
}
.signup {
  &__field {
    & + & {
      margin-top: 1.25rem;
    }
  }
  &__footer {
    margin-top: 2.5rem;
    text-align: center;
  }
  &__submit {
    min-width: 165px;
  }
  &__error {
    margin-top: 1.25rem;
  }
}
</style>
