<template>
  <main-card class="security-password">
    <div class="security-password__form" @keyup.enter="submit">
      <div v-for="item in fields" :key="item.key" class="security-password__field">
        <base-input
          v-model="formData[item.name]"
          :type="item.type"
          :required="item.required"
          :custom-error-messages="
            customValidationMsgs[item.name] ? customValidationMsgs[item.name] : null
          "
          :pattern="item.key === 'confirm' ? formData.passwd : null"
          use-reactive-validation
          autocomplete="off"
        >
          {{ $t(`fields.${item.key}.label`) }}
        </base-input>
      </div>
    </div>
    <template #bodyEnd>
      <base-button
        class="security-password__submit"
        :disabled="!hasChanges || !isValid"
        :loading="isLoading"
        @click="submit"
      >
        {{ $t('submit') }}
      </base-button>
    </template>
  </main-card>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard.vue';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import mixin from '../../mixins/index';
import showErrorModal from '@/mixins/showErrorModal';
export default {
  name: 'SecurityPassword',
  components: { MainCard, BaseInput },
  mixins: [mixin, showErrorModal],
  data() {
    return {
      formData: {},
      initialBackup: '',
      customValidationMsgs: {
        confirm: { patternMismatch: this.$t('fields.confirm.patternMismatch') },
      },
      isReady: false,
      isLoading: false,
    };
  },
  computed: {
    fields() {
      if (!this.profile || !this.profile.passwordSettings) return [];
      return this.profile.passwordSettings;
    },
    isValid() {
      return (
        this.fields.filter(i => i.required).every(i => !!this.formData[i.name]) &&
        this.formData.passwd === this.formData.confirm
      );
    },
    hasChanges() {
      const filtredField = ['passwd', 'confirm'];
      // не следим за полем старый пароль, который может браузером обновляться
      const filtredFormData = Object.fromEntries(filtredField.map(x => [x, this.formData[x]]));
      return this.initialBackup !== Object.values(filtredFormData).join('');
    },
  },
  watch: {
    fields(val) {
      this.setInitialFormData(val);
    },
    isReady(val) {
      this.$emit('ready-state-change', val);
    },
    hasChanges(val) {
      this.$emit('form-data-change', val);
    },
  },
  mounted() {
    if (this.fields.length) this.setInitialFormData(this.fields);
  },
  beforeDestroy() {
    this.clear();
  },
  methods: {
    setInitialFormData(list) {
      this.initialBackup = '';
      this.formData = list.reduce((acc, item) => {
        if (acc[item.name] !== 'old_passwd') {
          acc[item.name] = item.value;
          this.initialBackup += item.value;
          return acc;
        }
      }, {});
      this.isReady = true;
    },
    submit(e) {
      e.preventDefault();
      if (this.isValid) {
        this.isLoading = true;
        const pass = this.formData.passwd;
        this.updateProfileInfo(this.formData, 'usrparam')
          .then(() => {
            this.$emit('submit', this.formData);
            this.saveCredentials(this.profile.name, pass);
            this.clear();
          })
          .catch(e => this.showError(e))
          .finally(() => (this.isLoading = false));
      }
    },
    /*showError(msg) {
      this.$modals.open({
        name: 'Error',
        title: this.$t('error'),
        text: msg,
        footer: {
          centered: true,
          cancel: {
            props: { title: this.$t('ok') },
            on: { click: () => this.$modals.close() }
          }
        }
      })
    },*/
    saveCredentials(name, password) {
      if ('PasswordCredential' in window) {
        const credential = new PasswordCredential({
          id: name,
          name,
          password,
        });
        navigator.credentials.store(credential).then(
          () => {
            console.info("Credential stored in the user agent's credential manager.");
          },
          err => {
            console.error('Error while storing the credential: ', err);
          }
        );
      }
    },
    clear() {
      this.formData = {};
      this.initialBackup = '';
      this.setInitialFormData(this.fields);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "fields": {
        "new": {
          "label": "Новый пароль"
        },
        "old": {
          "label": "Старый пароль"
        },
        "confirm": {
          "label": "Подтверждение пароля",
          "patternMismatch": "Пароли не совпадают"
        }
      },
      "submit": "Обновить пароль",
      "ok": "Понятно",
      "error": "Ошибка"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.security-password {

  &__form {
    +breakpoint(sm-and-up) {
      flexy(flex-start, flex-end, wrap);
    }
    +breakpoint(md-and-up) {
      flex-wrap: nowrap;
      padding-bottom: 1rem;
    }
  }

  &__field {
    +breakpoint(sm-and-up) {
      flex: 1 1 auto;
    }

    & + & {
      margin-top: 1.25rem;

      +breakpoint(sm-and-up) {
        margin-top: 0;
        margin-left: 1.5rem;
      }
    }
  }

  &__submit {
    width: 100%;
    white-space: nowrap;
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      width: auto;
    }
    +breakpoint(md-and-up) {
      margin-top: 0;
      margin-left: 1.5rem;
    }
  }
}
</style>
