<template>
  <base-collapser
    class="promo-banner"
    :class="classes"
    :banner="isBanner"
    :open="isOpen"
    @open:update="handleOpenUpdate"
  >
    <template #summary>
      <h3 class="promo-title__main" :class="classesMain">
        Продукты «Группы Астра» теперь можно купить в Rusonyx
      </h3>
    </template>
    <div class="promo-title">
      <div class="promo-title__block">
        <span class="promo-title__text">
          Rusonyx стали частью «Группы Астра» и готовы предложить вам ряд продуктов экосистемы для
          покупки: Astra Linux Server, RuBackup, Termidesk, СУБД Tantor, GitFlic и другие. Полный
          список ПО и детали приобретения узнавайте у менеджеров поддержки.
        </span>

        <base-button class="promo__btn" @click="prepareNewTicket">
          {{ $t('new_ticket') }}
        </base-button>
      </div>
    </div>
    <!--    <img class="promo-banner__img" src="./PromoImg.svg" />-->
    <!--    <div class="promo-close" @click.once="deleteBanner">-->
    <!--      <close></close>-->
    <!--    </div>-->
  </base-collapser>
</template>
<script>
import MainButton from '@/components/Buttons/MainButton.vue';
import Close from '@/components/Icons/Close.vue';
import { getHours } from '@/utils/getHours.js';
import storeMixin from '@/layouts/Support/mixins/index.js';
import provider from '@/mixins/provider';
import BaseCollapser from '@/components/BaseCollapser/BaseCollapser.vue';
export default {
  components: {
    BaseCollapser,
    // Close,
  },
  mixins: [storeMixin, provider],
  // props: {
  //   name: {
  //     type: String,
  //     default: () => 'пользователь',
  //   },
  // },
  data() {
    return {
      show: true,
      hours: getHours,
      isBanner: {
        details: 'collapser__banner',
        summary: 'collapser__summary',
        icon: 'collapser__icon',
      },
    };
  },
  computed: {
    isOpen() {
      return this.$store.state.moduleApp.isBannerOpened;
    },
    theme() {
      return this.$store.state.moduleApp.theme;
    },
    classes() {
      const base = 'promo-banner';
      return {
        [`${base}--open`]: this.isOpen,
        [`${base}--closed`]: !this.isOpen,
        [`${base}--${this.theme}`]: this.theme,
      };
    },
    classesMain() {
      const base = 'promo-title';
      return {
        [`${base}--open`]: this.isOpen,
        [`${base}--closed`]: !this.isOpen,
      };
    },
    // greeting() {
    //   return `${this.hours()}, ${this.name}`;
    // },
  },
  methods: {
    handleOpenUpdate(value) {
      this.$store.commit('moduleApp/SET_IS_BANNER_OPENED', value);
    },
    prepareNewTicket() {
      this.addNewTicket({
        propsOptions: 'Запрос не связан с услугой',
        isInternalValue: '3',
        size: 'medium',
        subjectValue: 'Краткая формулировка запроса',
        textValue: 'Текст запроса',
      });
    },
    // deleteBanner() {
    //   this.show = !this.show;
    // },
  },
};
</script>

<i18n>
{
  "ru":{
    "new_ticket": "Получить консультацию"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.promo-banner {
  // position: relative;
  margin-bottom: 56px;
  //min-height: 268px;
  box-sizing: border-box;
  // justify-content: space-between;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  //background: linear-gradient(63.03deg, #6400b5 3.18%, #c54663 72.73%);
  // mix-blend-mode: normal;
  // filter: blur(1px);
  // opacity: 0.56;
  // filter: blur(135.914px);
  //padding: 24px;
  //padding-bottom: 45px;
  align-items: flex-start;
  border-radius: 10px;
  justify-content: flex-start;
  gap: 15rem;

+breakpoint(md-and-down) {
  padding: 25px;
  //min-height: 234px;
}

+breakpoint(ms-and-down) {
  background-size: cover;
}

  &__img {
    position: relative;
    //bottom: 0px;
    //right: 20%;
    z-index: 0;

  +breakpoint(ms-and-down) {
    display: none;
  }
  }
  &--light {
    background: no-repeat url('~@/assets/images/banner.jpg');
  }
  &--dark {
    background: no-repeat url('~@/assets/images/banner_dark.jpg');
  }
  &--closed {
    padding: 5px 35px 5px 35px;
    background-size: 150% 150%;
    transition: padding 0.4s linear, background-size 0.3s linear;

  +breakpoint(ms-and-down) {
    padding: 5px 0 5px 15px;
  }
  }
  &--open {
    padding: 35px;
    background-size: 100% 100%;
    transition: padding 0.4s linear, background-size 0.3s linear;

  +breakpoint(ms-and-down) {
    padding: 5px 0 5px 15px;
    background-size: cover;
  }
  }
}
.promo-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 70%;


+breakpoint(md-and-down) {
  width: 100%;
  }
  &__main {
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;
    padding: 0px;
    margin: 0px;
    //color: #ffffff;
    margin-bottom: 24px;

  }
  &__text {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    width: 67%;
    /* or 125% */
    //color: #ffffff;
  +breakpoint(md-and-down) {
    width: 80%;
  }
  +breakpoint(ms-and-down) {
    width: 100%;
  }
  }

  &__block{
    display: flex;
    gap: 15px;
    align-items: center;

  +breakpoint(md-and-down) {
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }
  }

  &--closed {
    margin-bottom: 0;
    transition: margin-bottom 0.3s linear;
  }
  &--open {
    margin-bottom: 15px;
    transition: margin-bottom 0.3s linear;

  +breakpoint(md-and-down) {
    margin-bottom: 10px;
  }
  }
}

.promo-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.promo__btn {
  position: relative;
  //right: 10rem;
  //top: 3rem;
  z-index: 10;
  height: 50px;
}
</style>
