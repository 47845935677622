export const STATE = {
  current: '',
  currentServer: {},
  expense: [],
  images: [],
  updateMembers: true,
  containers: [],
  storagesHeaders: {},
  storages: [],
  s3Keys: [],
  noS3Key: true,
  defaultS3Key: {},
  tags: [],
  secrets: [],
  objects: {},
  objectsOfCurrentStorage: [],
  isLoading: true,
  dnsDefault: [],
  files: [],
  healthType: ['HTTP', 'HTTPS', 'PING', 'SCTP', 'TCP', 'TLS-HELLO', 'UDP-CONNECT'],
  healthMethod: ['CONNECT', 'DELETE', 'GET', 'HEAD', 'OPTIONS', 'PATCH', 'POST', 'PUT', 'TRACE'],
  lb_algorithm: [
    {
      name: 'LEAST_CONNECTIONS',
      key: '1',
      type: 'alg',
    },
    { name: 'ROUND_ROBIN', key: '2', type: 'alg' },
    { name: 'SOURCE_IP', key: '3', type: 'sticky' },
    { name: 'APP-cookie', key: '4', type: 'sticky' },
    { name: 'HTTP-cookie', key: '5', type: 'sticky' },
  ],
  firewalls: [],
  healthmonitors: [],
  listeners: [],
  flavorprofiles: {
    base: '6f4930e7-450b-46e7-b554-69a8c6d2b34b',

    advanced: '8368dd66-df00-47fd-8e80-3f190b95af43',
  },
  pools: [],
  flavors: [],
  balancers: [],
  flavorPrice: [],
  keypairs: [],
  networks: [],
  rules: [],
  list: [],
  prefix: [],
  localDisk: [],
  progressBar: 0,
  totalSize: 0,
  mode: 'grid',
  routers: [],
  ports: [],
  price: [],
  members: {},
  currentMembers: [],
  subnets: [],
  snapshots: [],
  quota_limits: {},
  floatingips: [],
  projects: [],
  privateImages: [],
  quotasServer: [],
  quotasNetwork: [],
  quotasVolume: [],
  servers: [],
  serverRequest: true,
  storageRequest: true,
  balancerRequest: true,
  sort: {
    prop: 'status',
    order: 'asc',
    server: {
      prop: 'status',
      order: 'asc',
    },
    disk: {
      prop: 'status',
      order: 'asc',
    },
    image: {
      prop: 'status',
      order: 'asc',
    },
    snap: {
      prop: 'status',
      order: 'asc',
    },
    float: {
      prop: 'status',
      order: 'asc',
    },
    private: {
      prop: 'status',
      order: 'asc',
    },
    subnet: {
      prop: 'name',
      order: 'asc',
    },
    router: {
      prop: 'network_id',
      order: 'asc',
    },
    port: {
      prop: 'name',
      order: 'asc',
    },
    firewall: {
      prop: 'name',
      order: 'asc',
    },
    rule: {
      prop: 'direction',
      order: 'asc',
    },
    container: {
      prop: 'name',
      order: 'asc',
    },
    balancer: {
      prop: 'name',
      order: 'asc',
    },
    pool: {
      prop: 'name',
      order: 'asc',
    },
  },
  tools: {},
  typesOfDisks: {},
  volumes: [],
};
