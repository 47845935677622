import { sortFuncsByOrder } from '@/utils/sortFunctions';
import { parseISO } from 'date-fns';

export const GETTERS = {
  GET_SORTED_LIST: state => {
    const list = state.list && state.list.length ? [...state.list] : [];
    const { prop, order } = state.sort;
    // console.log(state.list);
    list.sort((a, b) => sortFuncsByOrder[order](a[prop], b[prop]));
    return list;
  },
  GET_CURRENT: state => {
    return state.current;
  },
  GET_ALL: state => {
    return Object.values(state.services).reduce((acc, item) => {
      acc += +item.items_total;
      return acc;
    }, 0);
  },
  GET_COUNT: state => {
    return state.list && state.list.length ? state.list.length : 0;
  },
  getIdForReload: state => {
    return state.paymethod;
  },
  GET_FIRST_DATE_OST: state => {
    if (state.list && state.list.length) {
      if (state.list[0].expiredate_type === 'date') {
        return Math.floor(
          (new Date(parseISO(state.list[0].expiredate)) - new Date()) / (1000 * 60 * 60 * 24)
        );
      }
    }
  },
};
