import billMgrToolActions from '@/mixins/billmgr/billMgrToolActions';
import getLocalizedPeriod from '@/mixins/getLocalizedPeriod';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import storeMixin from '@/layouts/All/mixins/index';
import Vue from 'vue';
import breakpoints from '@/utils/breakpoints';
import wizardPay from './wizardPay';
import handleRedirect from '../billing/handleRedirect';
import qs from 'qs';
import PaymentMethodConfigurator from '@/models/BillMgr/PaymentMethodConfigurator';
import { pathNameByService } from '@/utils/getServices';
export default {
  mixins: [billMgrToolActions, getLocalizedPeriod, wizardPay, storeMixin, handleRedirect],
  data() {
    return {
      sp_model: null,
      sp_modal: null,
      sp_list: [],
      sp_tariff: null,
      sp_currentPeriod: '',
      sp_total: 0,
      startFunc: 'payment.add',
      configuratorClass: PaymentMethodConfigurator,
    };
  },
  computed: {
    sp_currentValue() {
      return this.sp_list.find(i => i.k === this.sp_currentPeriod) || null;
    },
    balance() {
      return this.$store.state.moduleProfile.profile.balance;
    },
    sp_isBalanceEnough() {
      return this.sp_total <= this.balance;
    },
    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
    isMobile() {
      return this.screenWidth < breakpoints.sm;
    },
    path() {
      return this.sp_tariff && this.sp_tariff.itemtype
        ? pathNameByService[this.sp_tariff.itemtype]
        : null;
    },
    providerCurrent() {
      return this.$store.getters['moduleProviders/provider'];
    },
    tools() {
      const splitted = this.moduleMain.split('.');
      // console.log(this.moduleMain, splitted);
      const path = splitted.reduce((acc, item) => {
        return acc[item];
      }, this.$store.state);
      return path.tools;
    },
    moduleMainPath() {
      // console.log(this.sp_tariff);
      return this.moduleMainProlong && this.moduleMainProlong.length > 0
        ? // ? this.moduleMain.replaceAll('.', '/')
          this.moduleMain
        : null;
    },
    // moduleMainPath() {
    //   return this.moduleMain.replaceAll('.', '/');
    // },
  },
  watch: {
    sp_isBalanceEnough: function (value) {
      // console.log(value);
      //   if (this.sp_modal) this.sp_toggleConfirmButtonState(value);
    },
  },
  methods: {
    singleProlongFree(tariff) {
      this.sp_tariff = tariff;
      const params = {
        id: tariff.id,
        func: 'service.prolong',
      };
      this.$modals.open({
        name: 'SingleProlong',
        title: this.$t('prolong.title'),
        component: BaseLoader,
        onOpen: inst => (this.sp_modal = inst),
        onClose: () => {
          this.sp_modal = null;
          this.sp_reset();
        },
        footer: {
          confirm: {
            props: { title: this.$t('prolong.free'), disabled: false },
            on: {
              click: () => {
                this.sp_activate();
              },
            },
          },
          cancel: {
            props: { title: this.$t('prolong.cancel'), disabled: false },
            on: {
              click: () => this.$modals.close(),
            },
          },
        },
      });
      this.fetchBillMgrAction(params)
        .then(data => {
          const { model, slist, fields } = data;
          if (model && slist && model.period) {
            this.sp_model = model;
            this.sp_list = slist.period.map(i => {
              return {
                ...i,
                cost: parseFloat(+i.cost),
                label: this.getLocalizedPeriod(i.k),
              };
            });
            this.sp_currentPeriod = this.sp_model.period;
            Vue.set(this.sp_modal, 'size', 'big');
            Vue.set(this.sp_modal, 'props', {
              tariff: this.sp_tariff,
              list: this.sp_list,
              model: model,
              // type: type,
              value: this.sp_currentValue,
              text: fields.prolong_warning.label,
            });
            Vue.set(this.sp_modal, 'on', {
              ready: () => {
                Vue.set(this.sp_modal.footer.cancel.props, 'disabled', false);
                Vue.set(this.sp_modal.footer.confirm.props, 'disabled', false);
              },
              change: value => {
                this.sp_currentPeriod = value.k;
              },
              ['change-total']: value => {
                this.sp_total = value;
              },
            });
            Vue.set(this.sp_modal, 'component', this.singleProlongComponentFirst);
          } else {
            throw new Error(this.$t('prolong.error'));
          }
        })
        .catch(() => {
          this.$modals.close({ name: this.sp_modal.name });
        });
    },
    sp_reset() {
      this.sp_model = null;
      this.sp_list = [];
      this.sp_tariff = null;
    },
    sp_activate(skip = false) {
      const params = {
        elid: this.sp_tariff.id,
        func: 'service.prolong',
        period: this.sp_currentPeriod,
        sok: 'ok',
        skipbasket: 'on',
        paymethod: this.providerCurrent === '3' ? '47' : '49',
        snext: 'ok',
      };
      let modal = null;
      this.$modals.open({
        name: 'SingleProlongResult',
        component: BaseLoader,
        closable: false,
        onOpen: inst => (modal = inst),
        onClose: () => (modal = null),
      });
      this.sendBillMgrAction(params).then(data => {
        if (data && data.ok) {
          this.fetchItems({
            item: this.sp_tariff.func,
            id: this.sp_tariff.id,
          }).then(data => {
            console.log(data);
            this.$store
              .dispatch('moduleAll/updateTariffList', { data: data.model, type: 'prolong' })
              .then(data => {
                console.log(data);
              });
          });
          this.showSuccessModal(this.$t('prolong.success_order'));
          this.$modals.close({ name: 'SingleProlongResult' });
          this.$modals.close({ name: 'SingleProlong' });
          if (this.path)
            this.$router
              .push({ name: this.path, params: { id: this.item.id || this.tariff.id } })
              .catch(err => {
                if (
                  err.name !== 'NavigationDuplicated' &&
                  !err.message.includes('Avoided redundant navigation to current location')
                ) {
                  this.showErrorModal(err);
                }
              });
          // }
        }
      });
    },
    sp_toggleConfirmButtonState(value) {
      Vue.set(this.sp_modal.footer.confirm.props, 'disabled', !value);
      const note = {
        text: this.$t('balance.notenough'),
        color: 'error',
      };
      Vue.set(this.sp_modal.footer, 'note', value ? null : note);
    },

    sp_updatePageData() {
      if (this.moduleMainPath && this.moduleMainPath.length > 0) {
        this.moduleMainPath.includes('moduleDomainsDnsHost')
          ? this.$store.dispatch(`${this.moduleMainPath}/updateDnsList`)
          : this.$store.dispatch(`${this.moduleMainPath}/updateList`);
      }
      this.$store.dispatch('moduleBasket/fetchBasket');
      this.$store.dispatch('moduleProfile/setProfileInfo');
    },
    showSuccessModal(text) {
      this.$modals.open({
        name: 'SuccessOrder',
        size: 'small',
        text,
      });
    },
  },
};
