import axios from 'axios';

const defs = {
  baseURL: 'https://cloudapi.rusonyx.ru',
};

const OpenStackApi = axios.create({
  baseURL: defs.baseURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: false,
});

OpenStackApi.interceptors.request.use(
  config => {
    const key = localStorage.getItem('stack');
    if (key) {
      config.headers['x-auth-token'] = key;
      config.headers['OpenStack-API-Version'] = 'volume 3.68, compute 2.90, octavia 2.5';
      config.baseURL = `${defs.baseURL}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

OpenStackApi.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

export default OpenStackApi;
