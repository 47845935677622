<template>
  <div class="dev-sandbox">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'DevSandbox',
};
</script>

<style lang="stylus" scoped></style>
