import { parseDateRu } from '@/utils/parsers/dateParser';
import { flatten } from 'lodash';
import { parseCost } from '@/utils/parsers/costParser';
import { BillMgrMeasure } from './measures';
import { BillMgrStatus } from './status';
import { VpsAddon } from './VpsAddons';
import { VpsServer } from '../VM/VpsServer';

const osMock = {
  // name: 'Rocky Linux 8 64bit',
  // name: 'Rocky Linux 8',
  img: 'centos.svg',
};
const ispPanel = ['176', '177', '178', '179', '180'];
const pleskPanel = ['39', '40', '41', '53'];

export class VpsTariff extends Object {
  constructor(args) {
    super(args);
    this._info = null;
    this._addonsExt = null;
    this._serverInfo = null;
    this._history = [];
    if (this.isValid(args)) {
      try {
        this._init(args);
      } catch (e) {
        console.error(e);
      }
    }
  }

  isValid(args) {
    return args.id && args.pricelist && args.status;
  }

  _init(args) {
    this.id = +args.id;
    this.pricelist = args.pricelist;
    this.in_pay = args.in_pay === 'on' || false;
    this.employeesuspend = args.employeesuspend || 'off';
    this.abusesuspend = args.abusesuspend || 'off';
    // this.status = new BillMgrStatus(this.in_pay ? 6 : args.status);
    this.status = new BillMgrStatus(
      this.in_pay === 'on'
        ? 6
        : this.employeesuspend === 'on'
        ? 8
        : this.abusesuspend === 'on'
        ? 7
        : args.status
    );
    this.cost = parseCost(args.cost) || 0;
    this.ip = args.ip || '';
    this.domain = args.domain || '';
    this.change_pricelist = args.change_pricelist || args.change_pl || '';
    this.period_id = args.period_id || 1;
    this.period = args.period_name || '';
    this.period_cost = args.period_cost || '';
    this.datacentername = args.datacentername || '';
    this.expiredate = Date.parse(args.expiredate) || parseDateRu(args.real_expiredate_l);
    this.expiredate_l = args.real_expiredate_l || '';
    this.os = osMock;
    this.prolong_available = args.prolong_available || 'off';
    if (args.mem) this.mem = new BillMgrMeasure(args.mem.value, args.mem.measure_str) || '';
    if (args.disc) this.disc = new BillMgrMeasure(args.disc.value, args.disc.measure_str) || '';
    if (args.ncpu) this.ncpu = new BillMgrMeasure(args.ncpu.value, 'CPU') || '';
    // autoprolong
    this.autoprolong_available = args.autoprolong_available || 'off';
    this.autoprolong_period = args.autoprolong_period || args.autoprolong_id || 'null';
    this.autoprolong_name = args.autoprolong || args.autoprolong_name || '';
    this.isPlesk = false;
    this.promise = args.promise || 'null';
    this.stored_method = args.stored_method;
    // this.nodeName = args.NodeName || '';
    // this.guestTools = args.GuestTools || {};
  }

  updateResources(list) {
    list.forEach(i => {
      // console.log(i);
      if (this[i] && this[i.intname]) this[i.intname].value = i.value;
    });
  }

  update(args) {
    this._init(args);
  }

  get specs() {
    const obj = {
      os: this.os,
      datacentername: this.datacentername,
      cost: this.cost,
    };
    if (this.ncpu) obj.cpu = this.ncpu;
    if (this.disc) obj.disc = this.disc;
    if (this.mem) obj.mem = this.mem;
    return obj;
  }

  get title() {
    return this.domain || this.pricelist;
  }

  get osName() {
    if (
      !this.serverInfo ||
      !this.serverInfo.oS ||
      !this.info ||
      !this.info.details ||
      !this.info.details.addons
    )
      return { name: '', svg: 'linux-base' };
    const addons = this.info.details.addons;
    const item = Object.keys(addons);
    const addonNumber = item.find(x => addons[x].itemtype === 'ostemplate');
    const osName = addons[addonNumber].enumerationitem_name;
    this.os.name = osName;
    const OSTemplates = [
      { os: 'almalinux', key: 1 },
      { os: 'debian', key: 2 },
      { os: 'ubuntu', key: 3 },
      { os: 'rockylinux', key: 4 },
      { os: 'centos', key: 5 },
    ];

    if (OSTemplates.find(x => x.os.includes(this.serverInfo.oS)))
      this.os.svg = OSTemplates.find(x => x.os.includes(this.serverInfo.oS)).os;

    return { name: osName, svg: this.os.svg };
  }

  get type() {
    if (!this.info || !this.info.details || !this.info.details.addons) return null;
    return this.info.details.addons;
  }

  get serverId() {
    if (!this.info || !this.info.customfields || !this.info.customfields.serverid) return null;
    return this.info.customfields.serverid.value;
  }

  get hasPanel() {
    if (!this.info || !this.info.customfields) return false;
    const { username, userpassword } = this.info.customfields;
    const vals = { yes: true, no: false };
    return username && vals[username.added] && userpassword && vals[userpassword.added];
  }

  get allow_processing_prolong() {
    return this.info && this.info.model && this.info.model.allow_processing_prolong
      ? this.info.model.allow_processing_prolong
      : this.prolong_available
      ? this.prolong_available
      : 'off';
  }

  get prolongAvailable() {
    if (this.employeesuspend === 'on' || this.abusesuspend === 'on') return false;
    return this.prolong_available ? this.prolong_available === 'on' : false;
  }

  get autoprolongAvailable() {
    if (this.employeesuspend === 'on' || this.abusesuspend === 'on') return false;
    return this.autoprolong_available ? this.autoprolong_available === 'on' : false;
  }

  get autoprolongSettings() {
    const value = this.autoprolong_period || this.info.model.autoprolong;
    return {
      list:
        this.info && this.info.model && this.info.slist.autoprolong
          ? this.info.slist.autoprolong
          : [],
      value,
      state: value && value !== 'null' ? 'on' : 'off',
      period: this.autoprolong_name || '',
    };
  }

  set info({ customfields, slist, groups, model, details }) {
    const hidden = [
      'needreboot',
      'panels',
      'password',
      'reboot',
      'username',
      'userpassword',
      'applicense',
      'appsecretkey',
    ];
    const groupsObj = groups.reduce((acc, item) => {
      const flat = flatten(item.fields);
      acc[item.name] = flat.filter(i => !hidden.includes(i));
      return acc;
    }, {});
    if (groupsObj.resources) this.updateResources(groupsObj.resources.map(i => customfields[i]));
    if (model.cost) {
      const costperiod = model.costperiod ? parseFloat(model.costperiod) : 1;
      this.cost = parseCost(model.cost / costperiod);
    }
    const addons = details.addon.reduce((acc, i) => {
      acc[`addon_${i.id}`] = i;
      return acc;
    }, {});
    this._info = {
      customfields,
      slist,
      model,
      details: {
        main: details.main,
        addons,
      },
      groups: groupsObj,
    };
    if (details && details.main.period_cost) this.period_cost = details.main.period_cost;
  }

  get info() {
    return this._info;
  }

  set addonsExt(list) {
    this._addonsExt = list;
  }

  get addonsExt() {
    return this._addonsExt;
  }

  get addons() {
    let list = [];
    if (this.info) {
      // console.log(this.info);
      const { customfields, slist } = this.info;
      const group = this.info.groups.default;
      list = group.reduce((acc, i) => {
        const item = customfields[i];
        if (item) {
          const obj = { label: item.label, value: item.value /*, type: item.intname*/ };
          if (item.name && item.value && slist[item.name] && item.name.includes('isp_')) {
            // console.log(item.name, item.value, slist[item.name]);
            // obj.value = slist[item.name].find(i => i.k === item.value).v;
            const value = item.value.split(',');
            const listValue = [];
            // console.log(value);
            value.forEach(val => {
              listValue.push(slist[item.name].find(i => i.k === val).v);
            });
            // console.log(listValue);
            obj.value = listValue.join(', ');
            // obj.value = slist[item.name].filter(i => i.k === item.value).v;
          }
          if (item.type === 'select') {
            obj.value = slist[item.name].find(i => i.k === item.value).v;
            obj.type = slist[item.name].find(i => i.k === item.value).v.includes('ISP')
              ? 'ISP'
              : slist[item.name].find(i => i.k === item.value).v.includes('Plesk')
              ? 'Plesk'
              : 'none';
          }
          if (item.type === 'slider' && item.intname === 'vpsbackup') {
            obj.value = obj.value + ' ' + item.desc;
          }
          if (item.name !== 'panelid') acc.push(obj);
        }
        return acc;
      }, []);
    }
    return list;
  }
  get licType() {
    // console.log(this.addons);
    if (
      this.addons &&
      this.addons.length > 0 &&
      this.addons.filter(x => x.type).filter(x => x.type !== 'none') &&
      this.addons.filter(x => x.type).filter(x => x.type !== 'none').length > 0
    ) {
      return this.addons.filter(x => x.type && x.type !== 'none')[0].type || 'none';
    } else return 'none';
  }

  get configurator() {
    // console.log('configTariff', this.status.code);
    let list = [];
    if (this.info) {
      const { customfields, groups, slist } = this._info;
      const group = groups.resources
        ? [...groups.resources, ...groups.default]
        : [...groups.default];
      list = group.reduce((acc, i) => {
        const item = customfields[i];
        if (!item) return acc;
        const addonDetails = this.info.details.addons[i];
        const addonExt = this.addonsExt ? this.addonsExt.find(i => i.intname === item.intname) : -1;
        const { addonlimit, cost } = addonDetails || {};
        if (item && item.name !== 'serverid') {
          const props = {};
          if (addonDetails && addonlimit) props.limit = addonlimit;
          if (addonDetails && cost) props.addonSum = cost;
          if (item.type === 'select' && slist[item.name]) props.options = slist[item.name];
          if (item.type === 'multiple' && slist[item.name]) props.options = slist[item.name];
          if (~addonExt) props.ext = addonExt;
          if (this.in_pay === 'on') props.readonly = 'yes';
          if (this.status.code !== 2) props.readonly = 'yes';
          acc.push(
            new VpsAddon({
              ...item,
              ...props,
            })
          );
        }
        return acc;
      }, []);
    }
    // console.log('configListReturn', list);
    return list;
  }

  set history(elem) {
    this._history = elem;
  }

  get history() {
    return this._history;
  }

  set serverInfo(args) {
    this._serverInfo = new VpsServer(args);
  }

  get serverInfo() {
    return this._serverInfo;
  }

  set serverState(str) {
    if (!this._serverInfo instanceof VpsServer) throw new Error('Server info is not defined yet');
    this._serverInfo.state = str;
  }

  get serverState() {
    if (!this._serverInfo || !this._serverInfo instanceof VpsServer) return null;
    return this._serverInfo.state;
  }

  get pricelistId() {
    if (!this.info || !this.info.model || !this.info.model.pricelist) return null;
    return parseInt(this.info.model.pricelist);
  }
}
