<template>
  <div class="base-checkbox" :class="classes">
    <div v-if="$slots.before" class="base-checkbox__before"><slot name="before" /></div>
    <div class="base-checkbox__field">
      <input
        :id="internalId"
        type="checkbox"
        class="base-checkbox__input"
        v-bind="$attrs"
        :value="value"
        :checked="state"
        :disabled="disabled"
        :true-value="trueValue"
        :false-value="falseValue"
        @change="onChange"
        @keyup.enter.stop="toggle"
        @focus="isFocused = true"
        @blur="isFocused = false"
      />
      <label :for="internalId" class="base-checkbox__label">
        <div class="base-checkbox__box">
          <transition name="fade">
            <svg-icon v-show="isChecked" name="tick" class="base-checkbox__check" />
          </transition>
        </div>
        <div v-if="label || $slots.default" class="base-checkbox__label-inner">
          <span class="base-checkbox__label-text">
            <slot>{{ label }}</slot>
            <span v-if="$attrs.required" class="base-checkbox__asterisk error-color">*</span>
          </span>
          <!--            v-tooltip="{ content: hint, placement: 'top-start', container: 'body' }"-->
          <plain-button
            v-if="hint"
            v-tooltip="{ content: hint, placement: 'top-start', container: false }"
            icon="help"
            size="small"
            color="dim"
            class="base-checkbox__hint"
            tabindex="-1"
            @click.stop
          />
        </div>
      </label>
    </div>
    <div v-if="$slots.after" class="base-checkbox__after"><slot name="after" /></div>
  </div>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    offer: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [Boolean, String, Array],
      default: undefined,
    },
    label: {
      type: String,
      default: '',
    },
    trueValue: {
      type: String,
      default: '',
    },
    falseValue: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      internalId: this.id || `uid_${(~~(Math.random() * 1e8)).toString(16)}`,
      isFocused: false,
    };
  },

  computed: {
    state() {
      if (typeof this.modelValue === 'undefined' && !this.trueValue && !this.falseValue) {
        return this.checked;
      }
      if ((this.value || this.modelValue) && this.trueValue && this.falseValue) {
        return this.value === this.trueValue || this.modelValue === this.trueValue;
      }
      if (Array.isArray(this.modelValue)) {
        return this.modelValue.indexOf(this.value) > -1;
      }

      return this.modelValue;
    },
    isChecked() {
      return Boolean(this.state);
    },
    classes() {
      const prefix = 'base-checkbox';
      return {
        [`${prefix}--checked`]: this.isChecked,
        [`${prefix}--disabled`]: this.disabled,
        [`${prefix}--readonly`]: this.readonly,
        [`${prefix}--focused`]: this.isFocused && !this.readonly,
      };
    },
  },
  watch: {
    checked(newValue) {
      if (newValue !== this.state) {
        this.toggle();
      }
    },
  },
  mounted() {
    if (this.checked && !this.state) {
      this.toggle();
    }
  },
  methods: {
    onChange() {
      this.toggle();
    },
    toggle() {
      if (this.readonly) return;
      let value = null;
      if (Array.isArray(this.modelValue)) {
        value = this.modelValue.slice(0);
        if (this.state) {
          value.splice(value.indexOf(this.value), 1);
        } else {
          value.push(this.value);
        }
      } else if (this.trueValue && this.falseValue) {
        value = this[`${!this.state}Value`];
      } else {
        value = !this.state;
      }
      this.$emit('change', value);
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.base-checkbox {
  color: inherit;
  font-size:inherit

  &__before {
    margin-bottom: 0.5em;
  }
  &__after {
    margin-top: 0.5em;
  }
  &__input {
    absolute(0px, 0px);
    opacity: 0;
    z-index: 0;
    min-width: 0;
  }
  &__field {
    position: relative;
  }
  &__label {
    position: relative;
    z-index: 1;
    flexy(flex-start, flex-start);
    cursor: pointer;

    &-inner {
      margin-left: 0.5rem;
      display: flex
    }

    &-text {
      font-size: 14px;
      line-height: 1.15rem;
      user-select: none;

      ^[0]--readonly & {
        opacity: 0.6;
      }
    }

    ^[0]--disabled & {
      opacity: 0.2;
      pointer-events: none;
    }

    ^[0]--readonly & {
      cursor: default;
    }
  }

  &__hint {
    vertical-align: middle;
    font-size: 14px;
    margin-left: 0.25rem!important;
  }

  &__box {
    flexy(center, center);
    flex: 0 0 1.25em;
    size(1.25em);
    line-height: 1;
    background-color: transparent;
    box-sizing: border-box;
    border: 1px solid var(--input-border);
    border-radius: $border-radius-smallest;
    position: relative;
    overflow: hidden;
    color: $color-light.white;
    transition: background $anim-base-duration ease, color $anim-base-duration ease, border-color $anim-base-duration ease;

    ^[0]--focused & {
      border-color: var(--input-border-focused);
    }

    ^[0]--readonly & {
      opacity: 0.5;
    }
  }

  &:hover {
    & ^[0]__box {
      //background-color: $hse-color-gray--light;
    }
  }

  &--checked {
    & ^[0]__box {
      background-color: $primary-color;
      border-color: transparent;
    }

    &:hover,
    &^[0]--focused {
      & ^[0]__box {
        background-color: darken($primary-color, 20%);
      }
    }
  }
}
</style>
