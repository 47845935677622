<template>
  <div class="base-basket-item">
    <div class="base-basket-item__title note-text">
      {{ subtitle }}
    </div>
    <div class="base-basket-item__info">
      <div class="base-basket-item__name medium-title">
        {{ title }}
      </div>
      <div v-if="item.addonsFormatted" class="base-basket-item__details note-text">
        <base-collapser
          :summary="{ more: $tc('addons', item.addonsFormatted.length) }"
          theme="plain"
          reverse
        >
          <div v-for="addon in item.addonsFormatted" :key="item.service.id + '-' + addon.intname">
            {{ addon.name }}
          </div>
        </base-collapser>
      </div>
      <!--      <div v-if="domainName" class="base-basket-item__details">-->
      <!--        {{ item.desc.split('>')[1].split('<')[0] }}-->
      <!--      </div>-->
      <div class="base-basket-item__summary">
        <div v-if="item['item.type'] !== 'vdc3'" class="base-basket-item__cost">
          <span
            v-if="item.discount_amount"
            class="base-basket-item__cost-discount standart-title error-color"
          >
            {{ $n(item.fullcost, 'currency') }}
          </span>
          <span class="standart-title">
            {{ $n(item.cost, 'currency') }}
          </span>
          <span v-if="+period.len !== -50" class="note-text">
            <!--            {{ ' / ' + $tc(`period.${period.type}_c`, period.len) }}-->
            {{ ' / ' + item.main.period }}
          </span>
        </div>
        <div class="base-basket-item__actions">
          <plain-button
            v-if="editable"
            v-tooltip="autoprolongTooltipOptions"
            :icon="autoprolongIcons[autoprolongState]"
            :loading="autoprolongLoading"
            color="dim"
            class="base-basket-item__actions-item"
            @click="editAutoprolong(autoprolongOppState)"
          />
          <svg-icon
            v-if="!editable"
            v-tooltip="autoprolongTooltipOptions"
            :name="autoprolongIcons[autoprolongState]"
            class="base-basket-item__actions-item note-color"
          />
          <plain-button
            v-if="deletable"
            icon="delete"
            color="del"
            class="base-basket-item__actions-item"
            @click="removeFromBasket"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BasketItem } from '@/models/BillMgr/Basket';
import { transformPeriodToMore } from '@/models/BillMgr/periods';
import PlainButton from '../Buttons/PlainButton.vue';
import BaseCollapser from '@/components/BaseCollapser/BaseCollapser.vue';
import { parseCost } from '@/utils/parsers/costParser';

export default {
  name: 'BaseBasketItem',
  components: { PlainButton, BaseCollapser },
  props: {
    item: {
      type: BasketItem,
      required: true,
      validator: value => value instanceof BasketItem,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      autoprolongIcons: {
        on: 'autoprolong-checked',
        off: 'autoprolong',
      },
      tooltipContainer: null,
      autoprolongLoading: false,
    };
  },
  computed: {
    title() {
      return this.domainName
        ? `${this.titleDomain} #${this.item.service.id}`.trim()
        : `${this.item.pricelistName} #${this.item.service.id}`.trim();
    },
    domainName() {
      // console.log(this.item['item.type']);
      return this.item['item.type'] === 'domain';
    },
    titleDomain() {
      return this.domainName && this.item.desc
        ? this.item.desc.split('>')[1].split('<')[0].split(' ')[2]
        : this.item.pricelist_name;
      // return this.domainName ? this.item.pricelist_name : '';
    },
    operationTitle() {
      if (this.item.operation === 'prolong') return this.$t('prolong');
      if (this.item.operation === 'changeaddon') return this.$t('changeaddon');
      return '';
    },
    subtitle() {
      // const domain = this.item.desc.split('>')[1].split('<')[0];
      // const isDomain = this.item['item.type'] === 'domain';
      const operationTitle = this.operationTitle;
      const nounCase = operationTitle ? 'r' : 'i';
      const str = operationTitle + ' ' + this.$tc(`itemtype.${this.item.service.type}`, nounCase);
      return str.trim();
    },
    period() {
      const transformed = transformPeriodToMore(this.item.service.period);
      return { type: transformed.periodType, len: transformed.periodLen };
    },
    autoprolongState() {
      return this.item.autoprolong === 'null' ? 'off' : 'on';
    },
    autoprolongOppState() {
      return this.autoprolongState === 'on' ? 'off' : 'on';
    },
    periodRaw() {
      return this.item.service.period;
    },
    tooltipContainerOld() {
      const modalContainer = this.$el ? this.$el.closest('.base-modal') : null;
      const headerBasketContainer = this.$el ? this.$el.closest('.header-basket') : null;
      return modalContainer || headerBasketContainer;
    },
    autoprolongTooltipOptions() {
      return this.tooltipContainer
        ? {
            content: this.$t(`autoprolong.state.${this.autoprolongState}`, {
              period: this.$tc(`period.${this.period.type}_c`, this.period.len),
            }),
            placement: 'top-end',
            trigger: 'manual',
            show: false,
            classes: 'standart-text',
            container: this.tooltipContainer,
          }
        : null;
    },
  },
  watch: {
    autoprolongState() {
      if (this.autoprolongLoading) this.autoprolongLoading = false;
    },
  },
  mounted() {
    const modalContainer = this.$el ? this.$el.closest('.base-modal') : null;
    const headerBasketContainer = this.$el ? this.$el.closest('.header-basket__popup') : null;
    this.tooltipContainer = modalContainer || headerBasketContainer;
  },
  methods: {
    removeFromBasket() {
      // console.log(this.item.id);
      if (
        localStorage.getItem('promo') &&
        localStorage.getItem('promo').length &&
        this.item.id === localStorage.getItem('promo')
      )
        localStorage.removeItem('promo');
      // this.remoteFromBasketGtm(this.item);
      this.$emit('remove', this.item);
    },
    editAutoprolong(state) {
      this.autoprolongLoading = true;
      const params = {
        func: `${this.item.service.type}.edit`,
        elid: this.item.service.id,
        autoprolong: state === 'on' ? this.periodRaw : 'null',
        sok: 'ok',
      };
      this.$emit('edit', params);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "itemtype": {
      "rps": "Аренда физического сервера",
      "dummy_ssl_rsnx_oa_migrate": "SSL Сертификаты",
      "licplesk": "Лицензии Plesk",
      "saas_services": "SaaS сервисы",
      "licvz": "Лицензии Virtuozzo",
      "bitrix_license": "Лицензии Bitrix",
      "acronis": "Бэкап Acronis",
      "dnshost": "DNS",
      "antiddosgcore": "Защита AntiDDos",
      "private": "Частное облако",
      "soft": "Лицензия ISP",
      "zmail": "Почта Zmail",
      "cdnedge": "CDN",
      "dedic": "Выделенный сервер",
      "colocation": "Размещение сервера",
      "idprotect": "Защита данных владельца домена",
      "licmic": "Лицензии Офис"



    },
    "prolong": "Продление",
    "changeaddon": "Дополнение",
    "addons": "0 дополнений | 1 дополнение | {n} дополнения | {n} дополнений",
    "autoprolong": {
      "state": {
        "on": "Включено автоматическое продление на {period}",
        "off": "Автоматическое продление выключено"
      }
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.base-basket-item {
  &__info {
    +breakpoint(sm-and-up) {
      flexy(space-between, baseline, wrap);
      margin: 0 -0.5rem;
    }
  }

  &__name {
    +breakpoint(sm-and-up) {
      flex: 1 1 0;
      margin: 0 0.5rem;
    }
  }

  &__summary {
    flexy(space-between, center);
    margin-top: 0.75rem;

    +breakpoint(sm-and-up) {
      justify-items: flex-end;
      margin: 0 0.5rem;
    }
  }

  &__details {
    flex: 1 0 100%;
    order: 10;
    margin-top: 0.25rem;

    +breakpoint(sm-and-up) {
      margin: 0.25rem 0.5rem 0;
    }
  }

  &__title {
    margin-bottom: 0.25rem;
  }

  &__cost {
    flex: 0 0 auto;
    &-discount {
      //margin-right: 0.5rem;
      padding-right: 0.5rem;
      text-decoration: line-through;
    }
  }


  &__actions {
    height: 1.25rem;
    margin-left: 0.5rem;

    &-item {
      margin-left: 0.5rem;
      vertical-align: middle;
    }
  }
}
</style>
