import NewTicket from '@/layouts/Support/component/NewTicket';
import NewTicketCrm from '@/layouts/Support/component/NewTicketCrm';
import Vue from 'vue';
import NewTicketEdo from '@/layouts/Support/component/NewTicketEdo.vue';
import showErrorModal from '@/mixins/showErrorModal';
import Link from '@/components/Configurator/components/Link.vue';
import { IS_PROD } from '@/layouts/LoginPage/mixins/currentProvider';

export default {
  data() {
    return {
      prepareMessage: {},
      ticketId: '',
      hardCodeInfoForEdo: {},
    };
  },
  mixins: [showErrorModal],
  computed: {
    list() {
      return this.$store.getters['moduleSupport/GET_ALL_TICKETS'] || [];
    },
    host() {
      return window.location.href.split('#').slice(0, 1);
    },
    isLoading() {
      return this.$store.state.moduleSupport.isLoading;
    },
    doneMessage() {
      return `Заявка ${this.ticketId} на подключение ЭДО принята. <br /><br /> Обработка заявок осуществляется по рабочим дням в период с 10:00 до 17:00. Ожидайте нашего приглашения.`;
    },

    // text() {
    //   return `Заявка на подключение ЭДО принята.
    //     <br />
    //     <br />
    //     Обработка заявок осуществляется по рабочим дням в период с 10:00 до 17:00. Ожидайте нашего приглашения.
    //     <br />
    //     <br />
    //     Следить за статусом заявки можете здесь:`;
    // },
  },
  mounted() {},
  methods: {
    fetch() {
      return this.$store.dispatch('moduleSupport/fetchAllTickets');
    },
    link(id) {
      return ` ${IS_PROD ? this.appHost : 'http://localhost:8080/'}#/support/tickets/${id}`;
    },
    askUnsavedPageLeaveT(props = {}) {
      return new Promise((resolve, reject) => {
        this.$modals.open({
          name: 'PageLeave',
          ...props,
          title: !props.title ? this.$t('unsaved.title') : props.title,
          text: !props.text ? this.$t('unsaved.text') : props.text,
          onClose: () => reject(),
          onDismiss: () => reject(),
          footer: {
            confirm: {
              props: { title: this.$t('unsaved.confirm') },
              on: { click: () => resolve() },
            },
            cancel: {
              props: { title: this.$t('unsaved.cancel') },
              on: {
                click: () => {
                  this.$modals.close();
                  reject();
                },
              },
            },
          },
        });
      });
    },
    addNewTicket(props = {}) {
      this.$emit('click', 'click');
      let self = null;
      let hasChanges = false;
      this.$modals.open({
        name: 'TicketModal',
        // size: 'small',
        // size: 'medium',
        size: props?.size ? props.size : 'large',
        component: NewTicket,
        closeOnBackdrop: false,
        props: props,
        // title: this.$t('new_ticket'),
        on: {
          change: val => {
            // console.log('val');
            hasChanges = val;
          },
        },
        onOpen: instance => (self = instance),
        onDismiss: async () => {
          if (!hasChanges) return true;
          else {
            let res = false;
            await this.askUnsavedPageLeaveT()
              .then(() => {
                res = true;
                // this.$modals.close({ name: 'PageLeave' });
                // this.$modals.close({ name: 'SettingsModal' });
              })
              .catch(() => {
                res = false;
              });
            return res;
          }
        },
        onClose: () => {
          this.$store.dispatch('moduleSupport/fetchAllTickets');
          this.$store.dispatch('moduleSupport/resetFiles');
          self = null;
        },
      });
    },
    addNewTicketEdo(props = {}) {
      let self = null;
      let hasChanges = false;
      this.$modals.open({
        name: 'TicketModal',
        size: props?.size ? props.size : 'large',
        component: NewTicketEdo,
        closeOnBackdrop: false,
        props: props,
        on: {
          change: val => {
            this.prepareMessage = val;
            hasChanges = val;
            Vue.set(self.footer.confirm.props, 'disabled', false);
          },
          ready: () => {
            Vue.set(self.footer.confirm.props, 'disabled', false);
          },
          notready: () => {
            Vue.set(self.footer.confirm.props, 'disabled', true);
          },
        },
        onOpen: instance => (self = instance),
        onDismiss: async () => {
          if (!hasChanges) return true;
          else {
            let res = false;
            await this.askUnsavedPageLeaveT()
              .then(() => {
                res = true;
              })
              .catch(() => {
                res = false;
              });
            return res;
          }
        },
        onClose: () => {
          this.$store.dispatch('moduleSupport/fetchAllTickets');
          this.$store.dispatch('moduleSupport/resetFiles');
          self = null;
        },
        footer: {
          confirm: {
            props: { title: this.$t('send') },
            on: {
              click: () => {
                this.$modals.close();
                // this.sendInfoToTelegramm(this.prepareMessage);
                this.sendMessageTo(this.prepareMessage);
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                self = null;
                this.$modals.close();
              },
            },
          },
        },
      });
    },
    async sendInfoToTelegramm(props) {
      let formData = new FormData();
      const hardCodeText = `*Пришел запрос на ЭДО\\.*
      
      Тикет\\: ${props.info.id}
      Клиент\\: ${props.info.payersId}
      ИНН\\: ${props.info.inn}
      ${props.message}
      
      _Подключите клиента\\!_`;
      formData.append('text', hardCodeText);
      formData.append('chat_id', '-1002490969140'); //
      formData.append('parse_mode', 'MarkdownV2'); //
      return this.$store.dispatch('moduleStart/sendEdoToTelegramm', formData).then(data => {
        console.log(data);
      });
    },
    addNewTicketCrm(props = {}) {
      this.$emit('click', 'click');
      let self = null;
      let hasChanges = false;
      this.$modals.open({
        name: 'TicketModal',
        // size: 'small',
        // size: 'medium',
        size: 'large',
        component: NewTicketCrm,
        closeOnBackdrop: false,
        props: props,
        title: this.$t('new_ticket'),
        on: {
          change: val => {
            // console.log('val');
            hasChanges = val;
          },
        },
        onOpen: instance => (self = instance),
        onDismiss: async () => {
          if (!hasChanges) return true;
          else {
            let res = false;
            await this.askUnsavedPageLeaveT()
              .then(() => {
                res = true;
                // this.$modals.close({ name: 'PageLeave' });
                // this.$modals.close({ name: 'SettingsModal' });
              })
              .catch(() => {
                res = false;
              });
            return res;
          }
        },
        onClose: () => {
          // this.$store.dispatch('moduleSupport/fetchAllTickets');
          // this.$store.dispatch('moduleSupport/resetFiles');
          self = null;
        },
      });
    },
    sendMessageTo(props) {
      let formData = new FormData();
      formData.append('auth', localStorage.getItem('token'));
      formData.append('sok', 'ok');
      formData.append('message', props.message);
      formData.append('subject', props.theme);
      formData.append('client_department', props.department);
      formData.append('out', 'bjson');
      formData.append('func', 'clientticket.edit');

      this.$store
        .dispatch('moduleSupport/sendMessage', formData)
        .then(async data => {
          this.ticketId = data?.id;
          // console.log(this.prepareMessage);
          // await this.sendInfoToTelegramm(this.prepareMessage).then(() => {
          this.$store.dispatch('moduleSupport/fetchServiceList').then(() => this.$nextTick());
          // });
          // if (data.ok) {
          //
          //
          // }
        })
        .catch(e => {
          this.$modals.close();
          this.showAlert(e.msg);
          this.isSending = false;
          this.showError(e);
        })
        .finally(() => {
          this.$modals.close();
          this.sendMessageToDone();
        });
    },
    makeModal(name) {
      this.$modals.open({
        name: name,
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
      });
    },
    showModal(link) {
      if (!this.modal) this.makeModal();
      Vue.set(this.modal, 'component', Link);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'props', { link: link, text: this.text, html: true });
      Vue.set(this.modal, 'footer', {
        cancel: {
          props: { title: this.$t('close') },
          on: { click: () => this.$modals.close() },
        },
      });
    },
    sendMessageToDone() {
      if (!this.modal) this.makeModal('sendMessageDone');
      Vue.set(this.modal, 'text', this.doneMessage);
      Vue.set(this.modal, 'html', true);
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'closeOnBackdrop', false);
      Vue.set(this.modal, 'footer', {
        centered: true,
        confirm: {
          props: { title: this.$t('btn.url') },
          on: {
            click: () => {
              window.location.href = `${this.host}#/support/tickets/${this.ticketId}`;
              this.$modals.close();
            },
          },
        },
        cancel: {
          props: { title: this.$t('btn.ok') },
          on: { click: () => this.$modals.close() },
        },
      });
      this.sendingMessage = false;
    },
  },
};
