import Vue from 'vue';

export default {
  methods: {
    showError(e) {
      // console.log(e);
      if (e.type && e.type === 'auth') {
        this.$modals.close();
        this.$router.push({ name: 'Login' }).catch(() => {
          // console.log('showError login auth');
        });
      } else if (e.data && e.data['NeutronError']) {
        // console.log(e);
        const textError = e.data['NeutronError'].message;
        this.$modals.open({
          name: 'Error',
          closeOnBackdrop: false,
          //title: this.$t('error'),
          text: textError,
          footer: {
            centered: true,
            confirm: {
              props: { title: this.$t('close') },
              on: { click: () => this.$modals.close() },
            },
          },
        });
      } else if (e.message && e.response && e.response.data && e.response.data.result === 'error') {
        // console.log(e);
        this.$modals.open({
          name: 'Error',
          closeOnBackdrop: false,
          //title: this.$t('error'),
          //     text: addErrorText ? addErrorText : e.message,
          text: e.message,
          footer: {
            centered: true,
            confirm: {
              props: { title: this.$t('close') },
              on: { click: () => this.$modals.close() },
            },
          },
        });
      } else if (e.message) {
        // console.log('40', e.message);

        let addErrorText;
        if (e.code === 'ERR_BAD_REQUEST') {
          addErrorText =
            e.response.data &&
            e.response.data['badRequest'] &&
            e.response.data['badRequest'].message
              ? e.response.data['badRequest'].message
              : e.message;
        }
        if (e.code === 'NeutronError') {
          addErrorText =
            e.response.data &&
            e.response.data['NeutronError'] &&
            e.response.data['NeutronError'].message
              ? e.response.data['NeutronError'].message
              : e.message;
        }
        // console.log(addErrorText);

        const err = addErrorText ? addErrorText : e.message;
        // console.log(err);
        this.$modals.open({
          name: 'Error',
          closeOnBackdrop: false,
          //title: this.$t('error'),
          text: err,
          // text: e.message,
          footer: {
            centered: true,
            confirm: {
              props: { title: this.$t('close') },
              on: { click: () => this.$modals.close() },
            },
          },
        });
        // } else if (e.message) {
        //   console.log('11111', e.code);
        //   const addErrorText =
        //     e.response.data &&
        //     e.response.data['NeutronError'] &&
        //     e.response.data['NeutronError'].message
        //       ? e.response.data['NeutronError'].message
        //       : e.message;
        //   console.log(addErrorText);
        //   this.$modals.open({
        //     name: 'Error',
        //     //title: this.$t('error'),
        //     text: addErrorText ? addErrorText : e.message,
        //     // text: e.message,
        //     footer: {
        //       centered: true,
        //       cancel: {
        //         props: { title: this.$t('ok') },
        //         on: { click: () => this.$modals.close() },
        //       },
        //     },
        //   });
      } else if (e.message) {
        this.$modals.open({
          name: 'Error',
          closeOnBackdrop: false,
          //title: this.$t('error'),
          text: addErrorText ? addErrorText : e.message,
          // text: e.message,
          footer: {
            centered: true,
            confirm: {
              props: { title: this.$t('close') },
              on: { click: () => this.$modals.close() },
            },
          },
        });
      } else if (e.response && e.response.data && e.code === 'ERR_BAD_REQUEST') {
        // console.log(e);
        const error = e.response.data;
        this.$modals.open({
          name: 'Error',
          closeOnBackdrop: false,
          //title: this.$t('error'),
          text: error,
          html: true,
          footer: {
            centered: true,
            confirm: {
              props: { title: this.$t('close') },
              on: { click: () => this.$modals.close() },
            },
          },
        });
      } else if (e.response && e.response.data && e.response.data['NeutronError'].message) {
        // console.log(e);
        const error = e.response.data['NeutronError'].message;
        this.$modals.open({
          name: 'Error',
          closeOnBackdrop: false,
          //title: this.$t('error'),
          text: error,
          footer: {
            centered: true,
            confirm: {
              props: { title: this.$t('close') },
              on: { click: () => this.$modals.close() },
            },
          },
        });
      } else {
        const error = e.msg ? e.msg : e;
        const modifyError = error.includes("''") ? error.replace("''", e.object) : error;
        this.$modals.open({
          name: 'Error',
          closeOnBackdrop: false,
          //title: this.$t('error'),
          text: modifyError,
          footer: {
            centered: true,
            confirm: {
              props: { title: this.$t('close') },
              on: { click: () => this.$modals.close() },
            },
          },
        });
      }
    },
    showAlert(text) {
      this.$modals.open({
        name: 'Alert',
        closeOnBackdrop: false,
        // title: this.$t('error.title'),
        text: text,
        footer: {
          centered: true,
          confirm: {
            props: { title: this.$t('close') },
            on: { click: () => this.$modals.close() },
          },
        },
      });
    },
  },
};
