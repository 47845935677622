export const sortFuncsByOrder = {
  asc: (a, b) => {
    if (a && a.toNumber && b && b.toNumber) return a.toNumber() - b.toNumber();
    if (a < b) return -1;
    else if (a > b) return 1;
    else return 0;
  },
  desc: (a, b) => {
    // console.log(a, b, typeof a, typeof b);
    if (a && a.toNumber && b && b.toNumber) return b.toNumber() - a.toNumber();
    if (a > b) return -1;
    else if (a < b) return 1;
    else return 0;
  },
};
