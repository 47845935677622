<template>
  <div class="country-phone-block">
    <text-block
      :config="finalPhoneConfig"
      :label="phone.label"
      :hint="phone.hint"
      class="country-phone-block__phone"
      @change="onPhoneChange"
    >
      <!--        :config="country.config"-->
      <template #fieldBefore>
        <select-block
          :config="finalCountryConfig"
          mode="labelimg"
          intname="countryPhone"
          class="country-phone-block__country"
          @change="onCountryChange"
        />
      </template>
    </text-block>
    <div class="country-phone-block__errors error-color small-text">
      <template v-for="e in currentErrors">
        {{ $t(`error.${e}`) }}
      </template>
    </div>
  </div>
</template>

<script>
import TextBlock from './TextBlock.vue';
import SelectBlock from './SelectBlock.vue';
import BaseAddon from '@/models/base/BaseAddon';
import Vue from 'vue';
export default {
  name: 'CountryPhoneBlock',
  components: { TextBlock, SelectBlock },
  props: {
    country: {
      type: BaseAddon,
      required: true,
      validator: value => value instanceof BaseAddon,
    },
    phone: {
      type: BaseAddon,
      required: true,
      validator: value => value instanceof BaseAddon,
    },
  },
  data() {
    return {
      formData: {
        country: this.country.config.value,
        phone: this.phone.config.value,
      },
      error: {
        countryMismatch: false,
      },
      customErrorMessages: {
        patternMismatch: this.$t('error.patternMismatch'),
      },
    };
  },
  computed: {
    imgBasePath() {
      return this.$store.state.moduleApp.host;
    },
    currentErrors() {
      return Object.keys(this.error).filter(k => this.error[k]);
    },
    finalPhoneConfig() {
      const config = Object.assign({}, this.phone.config);
      config.mask = '+7 (999) 999-99-99';
      return {
        ...config,
        value: this.formData.phone,
        customErrorMessages: this.customErrorMessages,
        // pattern: '^\\' + this.country.config.internalValue.code + '[0-9]*',
        // pattern: '^\\' + 7 + '[0-9]*',
        type: 'tel',
      };
    },
    finalCountryConfig() {
      //hide other country
      const config = Object.assign({}, this.country.config);
      // config.disabled = true;
      config.options = config.options.filter(x => x.value === '182');
      return { ...config, value: '182', required: false, disabled: true };
    },
  },
  beforeMount() {
    Vue.set(this.country.config, 'imgBase', this.imgBasePath);
    this.country.config.updateOptionsImages();
  },
  methods: {
    onCountryChange(obj) {
      this.formData.country = obj.value;
      this.country.config.value = obj.value;
      Vue.set(this.formData, 'phone', obj.code);
      Vue.set(this.phone.config, 'value', obj.code);
      Vue.set(this.phone.config, 'mask', obj.mask);
      if (this.currentErrors.length) this.resetErrors();
      this.onChange();
    },
    onPhoneChange(obj) {
      this.validateCountry(obj.value)
        .then(() => {
          if (this.currentErrors.length) this.resetErrors();
          this.formData.phone = obj.value;
          this.phone.config.value = obj.value;
          this.onChange();
        })
        .catch(e => {
          if (e === 'countryMismatch') {
            this.error.mismatch = true;
          } else console.error(e);
        });
    },
    validateCountry(val) {
      return new Promise((resolve, reject) => {
        // if (new RegExp(`^\\${this.country.config.internalValue.code}`).test(val)) resolve();
        // if (new RegExp('^\\+7').test(val)) resolve();
        resolve();
        // else reject('countryMismatch');
      });
    },
    onChange() {
      this.$emit('change', {
        [this.country.name]: this.formData.country,
        [this.phone.name]: this.formData.phone.replace('+', '+7'),
      });
    },
    resetErrors() {
      this.currentErrors.forEach(k => (this.error[k] = false));
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "error": {
      "patternMismatch": "Номер телефона не соответствует формату выбранной страны",
      "countryMismatch": "Номер телефона не соответствует выбранной стране"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/mixins/mixins';
.country-phone-block {
  &__label {
    flexy(flex-start, baseline);
    margin-bottom: 0.5rem;
  }
  &__hint {
    margin-left: 0.25rem!important;
    align-self: center;
  }
  &__fields {
    flexy(flex-start, center);
  }
  &__country {
    margin-right: 1rem;
  }
  &__phone {
    flex: 1 1 100%;
  }
  &__errors {
    margin-top: 0.5em;
  }
}
</style>
