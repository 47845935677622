export default {
  computed: {
    provider() {
      return this.$store.state.moduleProviders.current;
    },
    isAstra() {
      return this.$store.state.moduleProviders.current === '6';
    },
  },
};
