<template>
  <div class="start-page page">
    <h1 v-if="['4', '5'].includes(provider)" class="page-title start-page__alarm">
      <p class="large-title error-color" v-html="text" />
    </h1>
    <promo-banner v-if="provider"></promo-banner>
    <!--    :name="profile.realname"-->
    <h1 class="page-title">
      {{ greeting }}
    </h1>

    <div class="start-page__content">
      <!--      <h1 class="page-title">-->
      <!--        <p-->
      <!--          v-if="['4', '5'].includes(providerCurrent)"-->
      <!--          class="bigger-text error-color"-->
      <!--          v-html="text"-->
      <!--        />-->
      <!--      </h1>-->
      <page-block :title="summaryTitles">
        <div class="l-flex">
          <div class="l-col-12_sm-12_md-12 l-flex-12">
            <div v-for="item in grid" :key="item.key" class="l-col-12_sm-4_md-3_xl-2_lg-2__xs-12">
              <start-card
                :number="services[item.key]"
                :text="
                  $tc(
                    provider === '6' && item.key === 'stack'
                      ? 'summary.services.astra'
                      : provider === '6' && item.key === 'dedic'
                      ? `summary.services.${item.key}`
                      : `summary.services.${item.key}`,
                    services[item.key]
                  )
                "
                :color="getCardColor(item, services[item.key])"
                :to="item.to || null"
              />
            </div>
          </div>
        </div>
      </page-block>
    </div>
  </div>
</template>

<script>
import { getHours } from '@/utils/getHours.js';
import StartCard from './components/StartCard.vue';
import evalOperators from '@/utils/evalVariableOperators';
import PromoBanner from '@/components/PromoBanners/PromoStandart.vue';
import { currentProvider, findAllByKey } from '@/layouts/LoginPage/mixins/currentProvider';
import provider from '@/mixins/provider';
export default {
  components: {
    StartCard,
    PromoBanner,
  },
  mixins: [provider],
  data() {
    return {
      name: 'пользователь',
      hours: getHours,
      billing: {
        key: 'billing',
        to: { name: 'BillingPayments' },
        color: {
          value: 'error',
          cond: { operator: '>', value: 0 },
        },
        priority: 'first',
      },
      incedents: {
        key: 'incedents',
        to: { name: 'Support' },
        access: 'incident_docs',
        colorSuccess: 'success',
        colorWarning: 'alert',
        priority: 'alert',
      },
      baseServicesGrid: [
        {
          key: 'all',
          to: { name: 'all' },
          access: 'all',
          colorSuccess: 'success',
          colorWarning: 'error',
          priority: 'high',
        },
        {
          key: 'vps',
          to: { name: 'VPS' },
          access: 'vds',
          priority: 'high',
        },
        {
          key: 'saas_services',
          to: { name: 'saas_services' },
          access: 'saas_services',
          priority: 'high',
        },
        {
          key: 'hosting',
          to: { name: 'virtual' },
          access: 'pleskshared',
          priority: 'high',
        },
        {
          key: 'vhost',
          to: { name: 'vhost' },
          access: 'vhost',
          priority: 'high',
        },

        {
          key: 'clouds',
          to: { name: 'Clouds' },
          access: 'vdc2',
          priority: 'high',
        },
        {
          key: 'stack',
          to: { name: 'Stack' },
          access: 'vdc3',
          priority: 'high',
        },
        {
          key: 'domain',
          to: { name: 'Domains' },
          access: 'domain',
          priority: 'high',
        },
        {
          key: 'dnshost',
          to: { name: 'Domains' },
          access: 'dnshost',
          priority: 'high',
        },
        {
          key: 'acronis',
          to: { name: 'acronis' },
          access: 'acronis',
          priority: 'high',
        },
        {
          key: 'ssl',
          to: { name: 'SSL' },
          access: 'dummy_ssl_rsnx_oa_migrate',
          priority: 'medium',
        },
        {
          key: 'antiddosgcore',
          to: { name: 'antiddos' },
          access: 'antiddosgcore',
          priority: 'medium',
        },
        {
          key: 'cdn',
          to: { name: 'cdn' },
          access: 'cdnedge',
          priority: 'medium',
        },
        {
          key: 'dedic',
          to: { name: 'dedic' },
          access: 'dedic',
          priority: 'medium',
        },
        {
          key: 'colocation',
          to: { name: 'colo' },
          access: 'colocation',
          priority: 'medium',
        },
        {
          key: 'idprotect',
          to: { name: 'idprotect' },
          access: 'idprotect',
          priority: 'medium',
        },
        {
          key: 'plesk',
          to: { name: 'plesk' },
          access: 'licplesk',
          priority: 'medium',
        },
        {
          key: 'microsoft',
          to: { name: 'microsoft' },
          access: 'licmic',
          priority: 'medium',
        },
        {
          key: 'bitrix',
          to: { name: 'bitrix' },
          access: 'bitrix_license',
          priority: 'medium',
        },
        {
          key: 'virtuozzo',
          to: { name: 'virtuozzo' },
          access: 'licvz',
          priority: 'medium',
        },
        {
          key: 'zmail',
          to: { name: 'zmail' },
          access: 'zmail',
          priority: 'medium',
        },
        {
          key: 'soft',
          to: { name: 'soft' },
          access: 'soft',
          priority: 'medium',
        },
        {
          key: 'rps',
          to: { name: 'rent' },
          access: 'rps',
          priority: 'medium',
        },
        {
          key: 'microsoft_office',
          to: { name: 'mo365' },
          access: 'microsoft_office',
          priority: 'medium',
        },
      ],
    };
  },
  computed: {
    provider() {
      return this.$store.state.moduleProviders.current === '3';
    },
    grid() {
      const grid = [];
      Object.values(this.baseServicesGrid).forEach(item => {
        if (Object.keys(this.access).includes(item.access)) {
          item.number = this.services[item.access] || this.services[item.key] || 0;
          grid.push(item);
        }
      });
      const sorted = grid.sort((a, b) => b.number - a.number);

      if (this.$store.getters['moduleSupport/incedents']) sorted.splice(0, 0, this.incedents);

      if (!this.isAstra) sorted.splice(2, 0, this.billing);
      return sorted;
    },
    profile() {
      return this.$store.state.moduleProfile.profile;
    },
    access() {
      const names = Object.keys(this.$store.state.moduleProfile.access)
        .filter(key => key.includes('mainmenuservice.') || key.includes('incident_docs'))
        .reduce((obj, key) => {
          return Object.assign(obj, {
            [key.split('.')[1]]: this.$store.state.moduleProfile.access[key],
          });
        }, {});
      names.all = true;

      return names;
    },
    greeting() {
      return `${this.hours()}, ${this.profile.realname}`;
    },
    services() {
      return this.$store.getters['moduleStart/services'];
    },
    currentField() {
      return findAllByKey(currentProvider, this.currentHost).toString();
    },
    id() {
      return this.profile.account;
    },
    ostDays() {
      return this.$store.getters['moduleAll/GET_FIRST_DATE_OST'];
    },
    summaryTitles() {
      return this.$t('summary.title') + ' #' + this.id;
    },
    text() {
      if (['5'].includes(this.provider)) {
        return `Infobox присоединяется к компании Rusonyx.<br /><br />
Не забудьте оповестить бухгалтерию об изменении наших реквизитов.
        <br /> Подробнее по <a href="https://www.rusonyx.ru/blog/post/reorganizaciya-kompaniy-rusoniks/" target="_blank"> ссылке.</a>`;
      } else {
        return `Не забудьте оповестить бухгалтерию о нашей реорганизации.
        <br /> Подробнее по <a href="https://www.rusonyx.ru/blog/post/reorganizaciya-kompaniy-rusoniks/" target="_blank"> ссылке.</a>`;
      }
      //
      // return `Компания ${
      //   this.providerCurrent === '4' ? 'ООО "ЗЕНОН Н.С.П."' : 'ООО "Национальные Телекоммуникации"'
      // }
      //    находится в процессе  <a href="${
      //      this.providerCurrent === '4'
      //        ? 'https://www.rusonyx.ru/blog/post/reorganizaciya-kompaniy-rusoniks/'
      //        : 'https://www.rusonyx.ru/blog/post/reorganizaciya-kompaniy-rusoniks/'
      //    }" target="_blank">реорганизации</a>
      // в форме присоединения к ООО "Русоникс".`;
    },
  },
  watch: {
    'services.billing': function (event) {
      this.billing.number = event;
    },
  },
  mounted() {
    const profileAccess = this.$store.state.moduleProfile.access;
    this.$store.dispatch('moduleStart/fetchUserWithoutPayment');
    if (profileAccess?.customer) {
      this.$store.dispatch('moduleAll/fetchList');
      this.$store.dispatch('moduleBilling/moduleOrders/fetchList');
      this.$store.dispatch('moduleStart/fetchDasboardInfo', { func: 'dashboard.info' });
      this.$store.dispatch('moduleStart/fetchDasboardInfo', { func: 'dashboard.services' });
      this.$store.dispatch('moduleStart/fetchDesktop');
      this.$store.dispatch('moduleBilling/moduleDocs/modulePayment/fetchList');
      this.$store.dispatch('moduleSupport/fetchAllIncedent');
    }
  },
  beforeDestroy() {
    localStorage.removeItem('project');
    localStorage.removeItem('urlHash');
    localStorage.removeItem('redirectUrl');
    localStorage.removeItem('host');
  },
  methods: {
    checkCond(value, operator, valueForCompare) {
      return evalOperators[operator](value, valueForCompare);
    },
    getCardColor(settings, value) {
      let color = 'primary';
      if (settings.color && settings.color.cond) {
        if (this.checkCond(value, settings.color.cond.operator, settings.color.cond.value)) {
          color = settings.color.value;
        }
      } else if (settings.colorWarning === 'alert') {
        color = settings.colorWarning;
      } else if (settings.colorSuccess && settings.colorWarning) {
        if (this.ostDays && this.ostDays < 20) {
          color = settings.colorWarning;
        } else color = settings.colorSuccess;
      } else if (settings.color && !settings.color.cond) color = settings.color;
      return color;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "summary": {
      "title": "Сводка вашего аккаунта",
      "services": {
        "vps": "VPS серверов | VPS сервер | VPS сервера | VPS серверов",
        "hosting": "Виртуальных хостингов Plesk | Виртуальный хостинг Plesk | Виртуальных хостинга Plesk | Виртуальных хостингов Plesk",
        "vhost": "Виртуальных хостингов ISP | Виртуальный хостинг ISP | Виртуальных хостинга ISP | Виртуальных хостингов ISP",
        "rps": "Физических серверов | Физический сервер | Физических сервера | Физических серверов",
        "dedic": "Выделенных серверов | Выделенный сервер | Выделенных сервера | Выделенных серверов",
        "astrapro": "AstraStack Pro",
        "colocation": "Размещенных серверов | Размещенный сервер | Размещенных сервера | Размещенных серверов",
        "clouds": "Облачных серверов | Облачный сервер | Облачных сервера | Облачных серверов",
        "stack": "Публичных облаков | Публичное облако | Публичных облака | Публичных облаков",
        "astra": "Облаков Астра | Облако Астра | Облака Астра | Облаков Астра",
        "saas_services": "SaaS сервисов | SaaS сервис | SaaS сервиса | SaaS сервисов",
        "zmail": "Почта zmail",
        "cdn": "CDN",
        "all": "Активных услуг | Активная услуга | Активные услуги | Активных услуг ",
        "incedents": "Инцидентов | Инцидент | Инцидента | Инцидентов ",
        "idprotect": "Защита данных домена",
        "domain": "Доменов | Домен | Домена | Доменов ",
        "dnshost": "DNS хостинг",
        "acronis": "Облачных бэкапов | Облачный бэкап | Облачных бэкапа | Облачных бэкапов",
        "ftpbackup": "FTP бэкапов | FTP бэкап | FTP бэкапа | FTP бэкапов",
        "microsoft_office": "Лицензии Офис",
        "ssl": "SSL сертификатов | SSL сертификат | SSL сертификата | SSL сертификатов",
        "plesk": "Лицензий Plesk | Лицензия Plesk | Лицензии Plesk | Лицензий Plesk",
        "virtuozzo": "Лицензий Virtuozzo | Лицензия Virtuozzo | Лицензии Virtuozzo | Лицензий Virtuozzo",
        "microsoft": "Лицензий Офис | Лицензия Офис | Лицензии Офис | Лицензий Офис",
        "bitrix": "Лицензий Bitrix | Лицензия Bitrix | Лицензии Bitrix | Лицензий Bitrix",
        "soft": "Лицензий ispmanager | Лицензия ispmanager | Лицензии ispmanager | Лицензий ispmanager ",
        "tickets": "тикетов | тикет | тикета | тикетов",
        "antiddosgcore": "Анти DDoS",
        "billing": "Неоплаченных счетов | Неоплаченный счет | Неоплаченных счета | Неоплаченных счетов",
        "partners": "новых регистрации в партнерской программе | новая регистрация в партнерской программе | новые регистрации в партнерской программе | новых регистраций в партнерской программе"
      }
    },
    "news": {
      "title": "Новости и уведомления"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
.start-page {
  &__content {
    max-width: 90rem;
  }
  &__alarm {
    margin-bottom: 3rem;
    margin-top: 3rem;
    border: solid
    border-color $error-color;
    padding: 2rem
  }
  &__partners {
    &-card {
      max-width: 25rem;
    }
  }
  &__news {
    &-card {
      max-width: 58rem;
    }
  }
}
</style>
