<template>
  <component :is="tag" :to="to" class="main-logo">
    <img v-if="src" :src="src" :alt="alt" class="main-logo__img" />
    <svg-icon v-else :name="logo" class="main-logo__icon" />
  </component>
</template>

<script>
import { IS_PROD } from '@/layouts/LoginPage/mixins/currentProvider';

export default {
  name: 'MainLogo',
  props: {
    src: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      default: 'Rusonyx',
    },
    to: {
      type: [String, Object],
      default: null,
    },
  },
  computed: {
    tag() {
      return !!this.to ? 'router-link' : 'div';
    },
    // alt() {
    //
    // },
    provider() {
      return this.$store.state.moduleProviders.current
        ? this.$store.state.moduleProviders.current
        : null;
    },
    logo() {
      if (IS_PROD) {
        if (localStorage.getItem('host').includes('rusonyx')) {
          return 'logo';
        } else if (localStorage.getItem('host').includes('caravan')) {
          return 'logo-caravan';
        } else if (localStorage.getItem('host').includes('astracloud')) {
          // return 'logo-astrastack';
          return 'logo-astra-w';
        } else if (localStorage.getItem('host').includes('infobox')) {
          return 'logo-infobox';
        } else if (localStorage.getItem('host').includes('zenon')) {
          return 'logo-zenon';
        } else {
          return 'logo';
        }
      } else {
        if (this.provider === '2') {
          return 'logo-caravan';
        } else if (this.provider === '3') {
          return 'logo';
        } else if (this.provider === '4') {
          return 'logo-zenon';
        } else if (this.provider === '5') {
          return 'logo-infobox';
        } else if (this.provider === '6') {
          // return 'logo-astrastack';
          return 'logo-astra-w';
        } else return 'logo';
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
.main-logo {
  color: $color-light.white;
  &,
  &__img,
  &__icon {
    display: block;
    line-height: 1;
  }
  &__icon {
    width: 137px;
    height: 17px;
    +breakpoint(md-down) {
      width: 120px;
    }
  }
  &__text {
    font-size: 0.7em;
  }
}
</style>
