<template>
  <div class="header-popup-activator" :class="classes">
    <svg-icon :name="icon" class="header-popup-activator__icon" />
    <div v-if="notify > 0" class="header-popup-activator__notify">
      {{ notify }}
    </div>
    <div v-if="id" class="header-popup-activator__id">
      {{ id }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderPopupActivator',
  props: {
    notify: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    classes() {
      const base = 'header-popup-activator';
      return {
        [`${base}--has-notifies`]: this.notify,
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';


.header-popup-activator {
  position: relative;
  flexy(center, center);
  size(30px);
  padding: 0 0.25rem;

  +breakpoint(sm-and-up) {
    size(40px);
    padding: 0 0.5rem;
  }

  &--has-notifies {
    cursor: pointer;
  }

  &__icon {
    display: block;
    max-height: 24.5px
    size(100%);
    color: $color-light.light-blue;
  }

  &__notify {
    absolute(4px);
    right: 2px;
    circle(15px);
    flexy(center, center);
    text-align: center;
    font-size: 0.5rem;
    font-weight: 700;
    line-height: 1.2;
    background: $color-red.base;
    color: $color-light.white;
  }
}
</style>
