import Vue from 'vue';
import Link from '@/components/Configurator/components/Link';

export default {
  data() {
    return {
      text: `В большинстве браузеров предупреждение о заблокированном всплывающем окне вы можете увидеть вверху этой страницы, рядом с адресной строкой.
        <br />
        <br />
        Разрешите в вашем браузере всплывающие окна для личного кабинета.
        <br />
        <br />
        Для перехода в панель нажмите на ссылку:`,
    };
  },
  methods: {
    makeModal(props = {}) {
      this.$modals.open({
        name: 'AddModal',
        size: 'small',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
        ...props,
      });
    },
    showModal(link) {
      console.log(link);
      if (!this.modal) this.makeModal({ name: 'LinkModal' });
      Vue.set(this.modal, 'component', Link);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'props', { link: link, text: this.text, html: true });
      Vue.set(this.modal, 'footer', {
        cancel: {
          props: { title: this.$t('close') },
          on: { click: () => this.$modals.close() },
        },
      });
    },
  },
};
